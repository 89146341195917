import React, { useContext, useState, useEffect } from 'react';
import './Hesabim.css';
//import AuthContext from '../services/AuthContext';
import request from '../services/Request';
import $ from 'jquery';
import baseURL from '../services/baseURL';
import CacheItem from '../services/CacheItem';
import { NavLink } from "react-router-dom";
import HesabimBilgiCard from './HesabimBilgiCard.js';
import HesabimDegistirCard from './HesabimDegistirCard.js';
import HesabimSifreDegistirCard from './HesabimSifreDegistirCard.js';
import PageTitle from './PageTitle';
import Context from '../context/store'

function Hesabim() {
  const {state, dispatch} = useContext(Context);
  const [error, setError] = useState(false);
  const [style, setStyle] = useState({ });  

  //const context = useContext(AuthContext);
  
  useEffect(() => {
    const updateStyles = () => {
      if (window.innerWidth < 990) {
        setStyle({
          contentStyle: {
            marginTop: "0px",
          },
          containerxxlStyle: {
            marginBottom: "20px",
          },
          py5Style: {
            paddingTop: "0px",
            paddingBottom: "0px",
          },
          divStyle : {
            width: '100%',
          }
        });
      } else {
        setStyle({
          contentStyle: {
            marginTop: "20px",
          },
          containerxxlStyle: {
            marginBottom: "20px",
          },
          py5Style: {
            paddingTop: "10px",
            paddingBottom: "0px",
          },
        });
      }
    };

    window.addEventListener('resize', updateStyles);

    updateStyles();

    return () => {
      window.removeEventListener('resize', updateStyles);
    };
  }, []);


  useEffect(() => {
    dispatch({type:"SET_CURRENTPAGENAME",payload:"Hesabım"})

    dispatch({type:"SET_CURRENTSUBPAGENAME",payload:""})

    dispatch({type:"SET_CURRENTRECCOUNT",payload:-1})

    return () => {
     //unmount
    }

  }, []);
  if (error) {
    return <div>Bir hata oluştu.</div>;
  }

  return (
    <div className="Hesabim">

<section className="content" style={style.contentStyle} >

{/* hb */}
<div className="post d-flex flex-column-fluid" id="kt_post">

  <div id="kt_content_container" className="container-xxl">
  <div className="pagetitle">
                <PageTitle />
  </div>
  
  <div className="row gy-0 mb-6 mb-xl-12">
      
      <div className="col-md-12">

      <HesabimBilgiCard />

      </div>
      </div>

    <div className="row gy-0 mb-6 mb-xl-12">
    
      <div className="col-md-6">

        <div id="info" className="card  card-md-stretch me-xl-3 mb-md-0 mb-6">

        <HesabimDegistirCard />

</div>
       
      </div>



      <div className="col-md-6">

<div id="info" className="card  card-md-stretch me-xl-3 mb-md-0 mb-6">

      <HesabimSifreDegistirCard />

</div>
</div>
    </div>


   
  </div>
</div>
{/* h */}




</section>
      <section className="content">

        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-xxl">

            <div className="row gy-0 mb-6 mb-xl-12">
            
            </div>
            <div className="row gy-0 mb-6 mb-xl-12">
              <div className="col-md-6">
              
              </div>
              <div className="col-md-6">
               
              </div>
            </div>
          </div>
        </div>

      </section>
    </div>
  );

}

export default Hesabim;