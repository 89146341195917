import axios from 'axios';
import baseURL from './baseURL';

const api = axios.create({
  baseURL: baseURL(),
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
api.interceptors.response.use(
    (response) => response,
    async (error) => {

      const originalRequest = error.config;
  
      // If the error status is 401 and there is no originalRequest._retry flag,
      // it means the token has expired and we need to refresh it
      if (error.response.status === 401 && !originalRequest._retry) {
        
        originalRequest._retry = true;
  
        try {
          //const refreshToken = localStorage.getItem('refresh');
         // alert("refresh yap");
          const response = await axios.post(baseURL()+'/relogin', {  email:localStorage.userName, rol:localStorage.rol,firma:localStorage.firma,refreshToken: localStorage.refresh } , {headers: {
            // Overwrite Axios's automatically set Content-Type
            'Content-Type': 'application/json'
          }});
          //alert(JSON.stringify(response.data));
          const { accessToken } = response.data;
  
          localStorage.setItem('token', accessToken);
  
          // Retry the original request with the new token
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          return axios(originalRequest);
        } catch (error) {
          localStorage.clear();
          window.location.href = '/oturum-ac';
        }
      }
  
      return Promise.reject(error);
    }
  );

export default api
