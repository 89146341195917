import React, { useContext, useState, useEffect } from 'react';
import './RehberDetay.css';
import { Redirect, NavLink } from 'react-router-dom';
import request from '../services/Request';
import AuthContext from '../services/AuthContext';
import $ from 'jquery';
//import moment from 'moment/min/moment-with-locales.min';
import ReactTooltip from 'react-tooltip';
import baseURL from '../services/baseURL';
//import CacheItem from '../services/CacheItem';
import axios from 'axios';

function RehberDetay(props) {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [events, setEvents] = useState([]);
	const [unvaniadi, setUnvaniadi] = useState('');
	const [musno, setMusno] = useState('');
	// const [YeniBasvuruUrl, setYeniBasvuruUrl] = useState('');
	const [duzenleUrl, setduzenleUrl] = useState('');
	const [tcvergino, setTcvergino] = useState('');
	const [tuzelkisilik, setTuzelkisilik] = useState(false);
	const [sehir, setSehir] = useState('');
	const [ilce, setIlce] = useState('');
	const [adres, setAdres] = useState('');
	const [telefon, setTelefon] = useState('');
	const [faks, setFaks] = useState('');
	const [gsm, setGsm] = useState('');
	const [email, setEmail] = useState('');
	const [tipi, setTipi] = useState('');
	const [basvurular, setBasvurular] = useState([]);

	const context = useContext(AuthContext);

	const Basarili = (response) => {
		var rehber = response.data;
		var tel = rehber.telefon;
		if (tel != null && tel.length == 10) tel = '(' + tel.substr(0, 3) + ') ' + tel.substr(3, 3) + ' ' + tel.substr(6, 4);
		if (tel != null && tel.length == 7) tel = '(242) ' + tel.substr(0, 3) + ' ' + tel.substr(3, 7);
		var faks = rehber.faks;
		if (faks != null && faks.length == 10) faks = '(' + faks.substr(0, 3) + ') ' + faks.substr(3, 3) + ' ' + faks.substr(6, 4);
		if (faks != null && faks.length == 7) faks = '(242) ' + faks.substr(0, 3) + ' ' + faks.substr(3, 7);
		var gsm = rehber.gsm;
		if (gsm != null && gsm.length == 10) gsm = '(' + gsm.substr(0, 3) + ') ' + gsm.substr(3, 3) + ' ' + gsm.substr(6, 4);
		if (gsm != null && gsm.length == 7) gsm = '(242) ' + gsm.substr(0, 3) + ' ' + gsm.substr(3, 7);
		setLoading(false);
		setUnvaniadi(rehber.unvaniadi);
		setMusno(rehber.musno);
		setTcvergino(rehber.tcvergino);
		setTuzelkisilik(rehber.tuzelkisilik);
		setSehir(rehber.sehir);
		setIlce(rehber.ilce);
		setAdres(rehber.adres);
		setTelefon(tel);
		setFaks(faks);
		setGsm(gsm);
		setEmail(rehber.email);
		setTipi(rehber.tipi);
		setBasvurular(JSON.parse(rehber.basvurular));
		//$("#timeline").height($('#info').height() - 70);
	}

	const Basarili_Offline = (response) => {

		var rehber = context.DATA;
		var tel = rehber.telefon;
		if (tel != null && tel.length == 10) tel = '(' + tel.substr(0, 3) + ') ' + tel.substr(3, 3) + ' ' + tel.substr(6, 4);
		if (tel != null && tel.length == 7) tel = '(242) ' + tel.substr(0, 3) + ' ' + tel.substr(3, 7);
		var faks = rehber.faks;
		if (faks != null && faks.length == 10) faks = '(' + faks.substr(0, 3) + ') ' + faks.substr(3, 3) + ' ' + faks.substr(6, 4);
		if (faks != null && faks.length == 7) faks = '(242) ' + faks.substr(0, 3) + ' ' + faks.substr(3, 7);
		var gsm = rehber.gsm;
		if (gsm != null && gsm.length == 10) gsm = '(' + gsm.substr(0, 3) + ') ' + gsm.substr(3, 3) + ' ' + gsm.substr(6, 4);
		if (gsm != null && gsm.length == 7) gsm = '(242) ' + gsm.substr(0, 3) + ' ' + gsm.substr(3, 7);
		setLoading(false);
		setUnvaniadi(rehber.unvaniadi);
		setMusno(rehber.musno);
		setTcvergino(rehber.tcvergino);
		setTuzelkisilik(rehber.tuzelkisilik);
		setSehir(rehber.sehir);
		setIlce(rehber.ilce);
		setAdres(rehber.adres);
		setTelefon(tel);
		setFaks(faks);
		setGsm(gsm);
		setEmail(rehber.email);
		setTipi(rehber.tipi);
		//setBasvurular(JSON.parse(rehber.basvurular) );
	}
	const Basarisiz = (status) => {
		setError(true);
		setLoading(false);
	}

	useEffect(() => {

		context.setCurrentPageName("Rehber");
		context.setCurrentSubPageName("Detay");
		context.setCurrentRecCount(-1);
		
		context.rehberdetay_postparam = { 'id': props.id };

		request('GET', '/getrehberdetay', context.rehberdetay_postparam, Basarili, Basarisiz, '');

		$(window).on('load resize', function () {
			setTimeout(() => {
				$("#timeline").height($('#info').height() - 70);
			}, "1");
		});

		return () => {
			//unmount
		}

	}, []);


	// var detaytable = document.getElementById("detaytable");
	// var basvuruteklifsozlesme = document.getElementById("basvuruteklifsozlesme");
	// if (detaytable.scrollTop!=0) this.context.setHTML(null);
	// if (basvuruteklifsozlesme.scrollTop!=0) this.context.setHTML(null);

	const duzenleClick = (e) => {
		e.preventDefault();
		setduzenleUrl("/rehber/duzenle/" + props.id);
	}

	const YeniBasvuruClick = (e) => {
		e.preventDefault();
		//setYeniBasvuruUrl('/basvurular/ekle/' + props.id);
	}

	const getList_Basarili = (response) => {
		var bilgi = response.data;
		alert(bilgi);
	}

	const getList_Basarisiz = (e) => {
	}

	const getData = async (url) => {

		const { data } = await axios.get(url);

		return data;
	}

	const getList = (path) => {
		var url = "";
		if (!path) url = baseURL() + '/getlistfilesinfolder?id=' + props.id; else url = baseURL() + '/getlistfilesinfolder?id=' + props.id + '&&path=' + path;
		url = url.trim();
		return getData(url);
	}
	const Template = () => {
		return (
			<div className="RehberDetay">

					<section className="content">

						{/* hb */}
						<div className="post d-flex flex-column-fluid" id="kt_post">
							<div id="kt_content_container" className="container-xxl">

								<div className="row gy-0 mb-6 mb-xl-12">
									<div className="col-md-">
										<div id="info" className="card  me-xl-3 mb-md-0 mb-6">
											<div className="card-header">

												<div className="card-title">
													<h4 className="fw-bolder">Müşteri</h4>
												</div>

												<div className="card-toolbar">

													<a className="btn btn-light-primary btn-sm" href="#" onClick={duzenleClick} ><i className="la la-edit"></i>Düzenle</a>
												</div>

											</div>

											<div className="card-body">


												<div className="d-flex flex-wrap py-5">
													<div className="flex-equal me-5">
														<table className="table table-flush fw-bold gy-1">
															<tr>
																<td className="text-muted min-w-125px w-125px">Adı/Ünvanı</td>
																<td className="text-gray-800">{unvaniadi}</td>
															</tr>
															<tr>
																<td className="text-muted min-w-125px w-125px"> Müşteri No</td>
																<td className="text-gray-800">{musno}</td>
															</tr>
															<tr>
																<td className="text-muted min-w-125px w-125px">{tuzelkisilik ? 'Vergi No' : 'TC Kimlik No'}</td>
																<td className="text-gray-800">{tcvergino}</td>
															</tr>
															<tr>
																<td className="text-muted min-w-125px w-125px">Şehir</td>
																<td className="text-gray-800">{sehir}</td>
															</tr>
															<tr>
																<td className="text-muted min-w-125px w-125px">İlçe</td>
																<td className="text-gray-800">{ilce}</td>
															</tr>
															<tr>
																<td className="text-muted min-w-125px w-125px">Adres</td>
																<td className="text-gray-800">{adres}</td>
															</tr>
														</table>
													</div>
													<div className="flex-equal">
														<table className="table table-flush fw-bold gy-1">
															<tr>
																<td className="text-muted min-w-125px w-125px">Rehber No</td>
																<td className="text-gray-800">{props.id}</td>
															</tr>
															<tr>
																<td className="text-muted min-w-125px w-125px">Kişi</td>
																<td className="text-gray-800">{tuzelkisilik ? 'TÜZEL' : 'GERÇEK'}</td>
															</tr>
															<tr>
																<td className="text-muted min-w-125px w-125px">Email</td>
																<td className="text-gray-800">
																	<a href="#" className="text-gray-900 text-hover-primary">{email}</a>
																</td>
															</tr>
															<tr>
																<td className="text-muted min-w-125px w-125px">Telefon</td>
																<td className="text-gray-800">{telefon}
																</td>
															</tr>
															<tr>
																<td className="text-muted min-w-125px w-125px">Mobil</td>
																<td className="text-gray-800">{gsm}

																</td>
															</tr>
															<tr>
																<td className="text-muted min-w-125px w-125px">Faks</td>
																<td className="text-gray-800">{faks}
																</td>
															</tr>
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>
									
								</div>
								
								<div className="modal fade" id="kt_modal_new_ticket" tabindex="-1" aria-hidden="true">
									<div className="modal-dialog modal-dialog-centered mw-750px">
										<div className="modal-content rounded">
											<div className="modal-header pb-0 border-0 justify-content-end">
												<div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
													<span className="svg-icon svg-icon-1">
														<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
															<rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
															<rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
														</svg>
													</span>
												</div>
											</div>
											<div className="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
												<form id="kt_modal_new_ticket_form" className="form" action="#">
													<div className="mb-13 text-center">
														<h1 className="mb-3">Create Ticket</h1>
														<div className="text-gray-400 fw-bold fs-5">If you need more info, please check
															<a href="" className="fw-bolder link-primary">Support Guidelines</a>.</div>
													</div>
													<div className="d-flex flex-column mb-8 fv-row">
														<label className="d-flex align-items-center fs-6 fw-bold mb-2">
															<span className="required">Subject</span>
															<i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Specify a subject for your issue"></i>
														</label>
														<input type="text" className="form-control form-control-solid" placeholder="Enter your ticket subject" name="subject" />
													</div>
													<div className="row g-9 mb-8">
														<div className="col-md-6 fv-row">
															<label className="required fs-6 fw-bold mb-2">Product</label>
															<select className="form-select form-select-solid" data-control="select2" data-hide-search="true" data-placeholder="Select a product" name="product">
																<option value="">Select a product...</option>
																<option value="1">HTML Theme</option>
																<option value="1">Angular App</option>
																<option value="1">Vue App</option>
																<option value="1">React Theme</option>
																<option value="1">Figma UI Kit</option>
																<option value="3">Laravel App</option>
																<option value="4">Blazor App</option>
																<option value="5">Django App</option>
															</select>
														</div>
														<div className="col-md-6 fv-row">
															<label className="required fs-6 fw-bold mb-2">Assign</label>
															<select className="form-select form-select-solid" data-control="select2" data-hide-search="true" data-placeholder="Select a Team Member" name="user">
																<option value="">Select a user...</option>
																<option value="1">Karina Clark</option>
																<option value="2">Robert Doe</option>
																<option value="3">Niel Owen</option>
																<option value="4">Olivia Wild</option>
																<option value="5">Sean Bean</option>
															</select>
														</div>
													</div>
													<div className="row g-9 mb-8">
														<div className="col-md-6 fv-row">
															<label className="required fs-6 fw-bold mb-2">Status</label>
															<select className="form-select form-select-solid" data-control="select2" data-placeholder="Open" data-hide-search="true">
																<option value=""></option>
																<option value="1" selected="selected">Open</option>
																<option value="2">Pending</option>
																<option value="3">Resolved</option>
																<option value="3">Closed</option>
															</select>
														</div>
														<div className="col-md-6 fv-row">
															<label className="required fs-6 fw-bold mb-2">Due Date</label>
															<div className="position-relative d-flex align-items-center">
																<div className="symbol symbol-20px me-4 position-absolute ms-4">
																	<span className="symbol-label bg-secondary">
																		<span className="svg-icon">
																			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																				<rect x="2" y="2" width="9" height="9" rx="2" fill="black" />
																				<rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="black" />
																				<rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="black" />
																				<rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="black" />
																			</svg>
																		</span>
																	</span>
																</div>
																<input className="form-control form-control-solid ps-12" placeholder="Select a date" name="due_date" />
															</div>
														</div>
													</div>
													<div className="d-flex flex-column mb-8 fv-row">
														<label className="fs-6 fw-bold mb-2">Description</label>
														<textarea className="form-control form-control-solid" rows="4" name="description" placeholder="Type your ticket description"></textarea>
													</div>
													<div className="fv-row mb-8">
														<label className="fs-6 fw-bold mb-2">Attachments</label>
														<div className="dropzone" id="kt_modal_create_ticket_attachments">
															<div className="dz-message needsclick align-items-center">
																<span className="svg-icon svg-icon-3hx svg-icon-primary">
																	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																		<path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM16 12.6L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L8 12.6H11V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18V12.6H16Z" fill="black" />
																		<path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black" />
																	</svg>
																</span>
																<div className="ms-4">
																	<h3 className="fs-5 fw-bolder text-gray-900 mb-1">Drop files here or click to upload.</h3>
																	<span className="fw-bold fs-7 text-gray-400">Upload up to 10 files</span>
																</div>
															</div>
														</div>
													</div>
													<div className="mb-15 fv-row">
														<div className="d-flex flex-stack">
															<div className="fw-bold me-5">
																<label className="fs-6">Notifications</label>
																<div className="fs-7 text-gray-400">Allow Notifications by Phone or Email</div>
															</div>
															<div className="d-flex align-items-center">
																<label className="form-check form-check-custom form-check-solid me-10">
																	<input className="form-check-input h-20px w-20px" type="checkbox" name="notifications[]" value="email" checked="checked" />
																	<span className="form-check-label fw-bold">Email</span>
																</label>
																<label className="form-check form-check-custom form-check-solid">
																	<input className="form-check-input h-20px w-20px" type="checkbox" name="notifications[]" value="phone" />
																	<span className="form-check-label fw-bold">Phone</span>
																</label>
															</div>
														</div>
													</div>
													<div className="text-center">
														<button type="reset" id="kt_modal_new_ticket_cancel" className="btn btn-light me-3">Cancel</button>
														<button type="submit" id="kt_modal_new_ticket_submit" className="btn btn-primary">
															<span className="indicator-label">Submit</span>
															<span className="indicator-progress">Please wait...
																<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
														</button>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* h */}

					</section>
			
				{/* <ReactTooltip place="bottom" type="dark" className='customtooltip'
					textColor='white' backgroundColor='#44567c' effect='solid' />
		        */}	
        </div> 

		);
	}

	// if (!navigator.onLine) 
	// return <div>Hata:İnternet bağlantısı yok...</div>;
	// if (YeniBasvuruUrl)
	// 	return <Redirect push to={YeniBasvuruUrl} />;

	if (duzenleUrl)
		return <Redirect push to={duzenleUrl} />;

	if (error) {
		//context.setLoader(false);
		return <div>Bir hata oluştu.</div>;
	}



	//context.HTML = Template();
	//context.setLoader(false);
	return Template();

}

export default RehberDetay;



