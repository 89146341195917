import React from 'react';
import './Footer.css';
import { useContext, useState, useEffect } from 'react';
import { NavLink,Link } from 'react-router-dom';
import AuthContext from '../services/AuthContext';
import $ from 'jquery';

function Footer() {
  const context = useContext(AuthContext);  

  useEffect(() => {


}, []);

    // if (!navigator.onLine) 
    // return <div>Hata:İnternet bağlantısı yok...</div>;

  return (
 
      <div class="footer bg-white py-1 d-flex flex-lg-column " id="kt_footer">

	<div class=" container-fluid  d-flex flex-column flex-md-row align-items-center justify-content-between">
	
		<div class="text-dark order-2 order-md-1">
			<span class="text-muted font-weight-bold mr-2">Hizmet Sağlayıcı:&nbsp;&nbsp;</span>
			<Link to="#" class="text-dark-75 text-hover-primary">{"Alfasoft"}</Link>
     
    </div>

		<div class="nav nav-dark">

		</div>

	</div>

</div>
  
);

}

export default Footer;