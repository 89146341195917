import React, { useContext, useState, useEffect } from 'react';
import './RehberDuzenle.css';
import { NavLink } from 'react-router-dom';
import toastr from 'toastr';
import request from '../services/Request';
import AuthContext from '../services/AuthContext';
import $ from 'jquery';
import Modal from 'react-modal';
import baseURL from '../services/baseURL';
import CacheItem from '../services/CacheItem';

const options = {
  placeholder: "Seçiniz",
  language: {
    noResults: function () {
      return "Sonuç bulunamadı...";
    }
  },
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
  },
  overlay: {
    backgroundColor: 'rgba(255, 255, 255, 0.75)'
  },
};
// datayı state e al render anında map et
//      this.setState({ loading: false });
//class RehberDuzenle extends React.Component {
  
  //static contextType = AuthContext;
  
 // state = { sehirler: [], ilceler: [] };
  
  function RehberDuzenle(props) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [html, setHtml] = useState(null);
    const [elemanlar, setElemanlar] = useState(['GERÇEK KİŞİ', 'TÜZEL KİŞİ']);
    const [unvaniadi, setUnvaniadi] = useState('');
    const [musno, setMusno] = useState('');
    const [tcvergino, setTcvergino] = useState('');
    const [tuzelkisilik, setTuzelkisilik] = useState(false);
    const [sehir, setSehir] = useState('');
    const [ilce, setIlce] = useState('');
    const [adres, setAdres] = useState('');
    const [telefon, setTelefon] = useState('');
    const [faks, setFaks] = useState('');
    const [gsm, setGsm] = useState('');
    const [email, setEmail] = useState('');
    const [tipi, setTipi] = useState('');
    const [sehirler, setSehirler] = useState([]);
    const [ilceler, setIlceler] = useState([]);
    const [style, setStyle] = useState({ });

    const context = useContext(AuthContext);

  const handleOpenModal = () => {
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
  }

  const handleChangeUnvaniAdi = (event) => {
    setUnvaniadi(event.target.value.toLocaleUpperCase('TR'));
  }

  const handleChangeTcVergino = (event) => {
      setTcvergino(event.target.value);
  }

  const Kaydet_Basarili = (response) => {
    context.DeleteFromCache(baseURL()+"/rehberduzenle?id="+props.id,"");
    context.DeleteFromCache(baseURL()+'/rehberdetay',JSON.stringify({ 'id': props.id }));       
    context.DeleteFromCache(baseURL()+'/getrehberdata',JSON.stringify(context.getrehberdata_postparam));

    toastr.options = {
      "positionClass": "toast-top-center",
      "preventDuplicates": true,
    }
    toastr["success"]('Bilgiler Kaydedildi...');
    setLoading(false);
  }

  const Kaydet_Basarisiz = (status) => {
    setLoading(false);
    toastr.options = { 
      "positionClass": "toast-top-center",
      "preventDuplicates": true,
    }
    if (status === 462) {
      toastr["error"]('İşlem başarısız oldu. </br> Bu TC Kimlik / Vergi No zaten kayıtlı...');
    }
    else {
      toastr["error"]('İşlem başarısız oldu');
    }
  }

  const Kaydet = (e) => {
    
    
    
    var form = document.getElementById('myForm');
    var isValid = form.checkValidity();
    if (isValid) {

        //setLoading(true);
      document.getElementById("kt_button_1").setAttribute("data-kt-indicator", "on");
        //mutate();
        
      if ($('#zz1').val() == "GERÇEK KİŞİ") setTuzelkisilik(false); else setTuzelkisilik(true);

      var sehirid = ($("#mm1")[0].selectedIndex).toString();

      var ilceadi = $("#nn1 option:selected").text();

      setLoading(true);
      
      request('POST', '/rehberduzenle',
        { 'id': props.id, 'tckimlikvergino': tcvergino, 'tuzelkisilik': tuzelkisilik, 'unvaniadi': unvaniadi, 'sehirid': sehirid, 'ilceadi': ilceadi },
        Kaydet_Basarili, Kaydet_Basarisiz,
        '');
    }
    
  }

  const Sil = () => {

  }

  const IlceYukle = (sehir) => {

    request('POST', '/ilcedoldur',
      { "sehiradi": sehir },
      IlceDoldur_Basarili, IlceDoldur_Basarisiz,
      '');
   
  }

  const IlceDoldur_Basarili = (response) => {

    var ilceler = response.data;

    setIlceler(ilceler);
    setIlce("...");
  }


  const IlceDoldur_Basarisiz = (status) => {

  }

  // const RehberDuzenle_Basarili = (response) => {
  //   var rehber = response.data;
      
  //     setSehirler(rehber.sehirler);
  //     setIlceler(rehber.ilceler);     
  //     setUnvaniadi(rehber.unvaniadi);
  //     setTuzelkisilik(rehber.tuzelkisilik);
  //     setMusno(rehber.musno);
  //     setTcvergino(rehber.tcvergino);
  //     setAdres(rehber.adres);
  //     setEmail(rehber.email);
  //     setTipi(rehber.tipi); 
  //     setSehir(rehber.sehir);
  //     setIlce(rehber.ilce);
  //     setLoading(false);
  //       if (tuzelkisilik === true) {
        
  //         $("#id_tcvergino").attr('maxlength', '10');
  //         $("#id_tcvergino").attr('minlength', '10');
         
  //       }
  //       else {
  //         $("#id_tcvergino").attr('maxlength', '11');
  //         $("#id_tcvergino").attr('minlength', '11');
         
  //       };

  //       $('#mm').on("select2:select", function (e) {
  //         IlceYukle(e.params.data.text);
  //       });

  // }


  // const RehberDuzenle_Basarili_Offline = (response) => {
   
  //   var rehber = context.DATA;
      
  //     setSehirler(rehber.sehirler);
  //     setIlceler(rehber.ilceler);     
  //     setUnvaniadi(rehber.unvaniadi);
  //     setTuzelkisilik(rehber.tuzelkisilik);
  //     setMusno(rehber.musno);
  //     setTcvergino(rehber.tcvergino);
  //     setAdres(rehber.adres);
  //     setEmail(rehber.email);
  //     setTipi(rehber.tipi); 
  //     setSehir(rehber.sehir);
  //     setIlce(rehber.ilce);
  //     setLoading(false);
  //       if (tuzelkisilik === true) {
        
  //         $("#id_tcvergino").attr('maxlength', '10');
  //         $("#id_tcvergino").attr('minlength', '10');
  
  //       }
  //       else {
  //         $("#id_tcvergino").attr('maxlength', '11');
  //         $("#id_tcvergino").attr('minlength', '11');
       
  //       };

  //       $('#mm').on("select2:select", function (e) {
  //         IlceYukle(e.params.data.text);
  //       });

  // }



  const RehberDuzenle_Basarili = (response) => {
		var rehber = response.data;
		var tel = rehber.telefon;
		if (tel != null && tel.length == 10) tel = '(' + tel.substr(0, 3) + ') ' + tel.substr(3, 3) + ' ' + tel.substr(6, 4);
		if (tel != null && tel.length == 7) tel = '(242) ' + tel.substr(0, 3) + ' ' + tel.substr(3, 7);
		var faks = rehber.faks;
		if (faks != null && faks.length == 10) faks = '(' + faks.substr(0, 3) + ') ' + faks.substr(3, 3) + ' ' + faks.substr(6, 4);
		if (faks != null && faks.length == 7) faks = '(242) ' + faks.substr(0, 3) + ' ' + faks.substr(3, 7);
		var gsm = rehber.gsm;
		if (gsm != null && gsm.length == 10) gsm = '(' + gsm.substr(0, 3) + ') ' + gsm.substr(3, 3) + ' ' + gsm.substr(6, 4);
		if (gsm != null && gsm.length == 7) gsm = '(242) ' + gsm.substr(0, 3) + ' ' + gsm.substr(3, 7);
		setLoading(false);
		setUnvaniadi(rehber.unvaniadi);
		setMusno(rehber.musno);
		setTcvergino(rehber.tcvergino);
		setTuzelkisilik(rehber.tuzelkisilik);
		setSehir(rehber.sehir);
		setIlce(rehber.ilce);
		setAdres(rehber.adres);
		setTelefon(tel);
		setFaks(faks);
		setGsm(gsm);
		setEmail(rehber.email);
		setTipi(rehber.tipi);
		//setBasvurular(JSON.parse(rehber.basvurular));
	}


  const RehberDuzenle_Basarisiz = (status) => {
    setError(true);
    setLoading(false);
  }

  useEffect(() => {
    const updateStyles = () => {
      if (window.innerWidth < 990) {
        setStyle({
          contentStyle: {
            marginTop: "0px",
          },
          containerxxlStyle: {
            marginBottom: "20px",
          },
          py5Style: {
            paddingTop: "0px",
            paddingBottom: "0px",
          },
          divStyle : {
            width: '100%',
          }
        });
      } else {
        setStyle({
          contentStyle: {
            marginTop: "20px",
          },
          containerxxlStyle: {
            marginBottom: "20px",
          },
          py5Style: {
            paddingTop: "10px",
            paddingBottom: "0px",
          },
        });
      }
    };

    window.addEventListener('resize', updateStyles);

    updateStyles();

    return () => {
      window.removeEventListener('resize', updateStyles);
    };
  }, []);

  useEffect(() => {

    context.setCurrentPageName("Rehber");
		context.setCurrentSubPageName("Düzenle");
		context.setCurrentRecCount(-1);

    var reqdata = { 'id': props.id };
     
		context.rehberdetay_postparam = { 'id': props.id };

		request('GET', '/getrehberdetay', context.rehberdetay_postparam, RehberDuzenle_Basarili, RehberDuzenle_Basarisiz, '');


    return () => {
     //unmount
    }

  }, []);


  const formPreventDefault = (e) => {
    e.preventDefault();
  }

  const kisilikSelected = () => {
  
    if ($('#zz1').val() == "GERÇEK KİŞİ") {
      setTuzelkisilik(false);
      setTcvergino('');
     }
      else 
      {
        setTuzelkisilik(true);
        setTcvergino('');
      }

    $("#id_tcvergino:text").val("");
    if ($('#zz1').val() == "GERÇEK KİŞİ") {

      // $("label[for*='tcverginolabel']").html("TC Kimlik No");
      //$("#id_tcvergino").attr("placeholder", "Şahıs vergi mükellefi ise doldurulmalıdır !");

      //   $("label[for*='ilgilikisilabel']").html("İrtibat Kurulacak Kişi");
      $("#id_tcvergino").attr('maxlength', '11');
      $("#id_tcvergino").attr('minlength', '11');

      //$("#id_tcvergino").rules("remove", "minlength");
      // $("#id_tcvergino").rules("add", { minlength: 11 });
      // $("label[for*='firmalabel']").html("Üretici Adı Soyadı");
      //  $("#vdairefield").rules("remove", "required");
      //  $("#yetkilifield").rules("remove", "required");                
    }

    if ($('#zz1').val() == "TÜZEL KİŞİ") {

      // $("label[for*='tcverginolabel']").html("Vergi No");
      //$("#id_tcvergino").attr("placeholder", "");
      //  $("label[for*='vdairelabel']").html("İrtibat Kurulacak Kişi");
      //  $("label[for*='ilgilikisilabel']").html("Vergi Dairesi");
      $("#id_tcvergino").attr('maxlength', '10');
      $("#id_tcvergino").attr('minlength', '10');
      // $("#id_tcvergino").val("");
      // $("#id_tcvergino").rules("remove", "minlength");
      //$("#id_tcvergino").rules("add", { minlength: 10 });
      // $("label[for*='firmalabel']").html("Firma Ünvanı");               
      // $("#vdairefield").rules("add", "required");
      //  $("#yetkilifield").rules("add", "required");
    }
  }

  const sehirSelected = () => {
    IlceYukle($('#mm1').val());
    setSehir($('#mm1').val() );
  }

  const ilceSelected = () => {
    setIlce($('#nn1').val() );
  }

  const Template = () => {

    return (

      <div className="RehberDuzenle">


<section className="content" style={style.contentStyle}>

{/* hb */}
<div className="post d-flex flex-column-fluid" id="kt_post">
    <div id="kt_content_container" className="container-xxl">

        <div className="row gy-0 mb-6 mb-xl-12">
            <div className="col-md-12">
                <div id="info" className="card  me-xl-3 mb-md-0 mb-6" style={{paddingBottom:'50px'}}>
                    <div className="card-header">

                        <div className="card-title">
                            <h4 className="fw-bolder">Müşteri</h4>
                        </div>

                        <div className="card-toolbar">

                            <button id="kt_button_1" onClick={(e) => Kaydet(e.target)} className="btn btn-primary btn-sm">
                                <span className="indicator-label">
                                    Kaydet
                                </span>
                                <span className="indicator-progress">
                                    Kaydet<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        
                        </div>

                    </div>

                    <div className="card-body">


                    <div className="d-flex flex-wrap py-5">
    <form id="myForm" method="post" autocomplete="off" onSubmit={formPreventDefault}>
   
    <div class="form-group mdv" style={{width: '100%',paddingBottom:'10px'}}>
    <label for="soyadilabel" class="required fs-6 fw-bold mb-2 control-label">Adı Soyadı / Ünvanı</label>
    <label class="error" for="soyadifield"></label>
    <input id="id_unvaniadi" name="ad" class="form-control form-control-solid" type="text" required="" maxlength="100" placeholder="" value="Tekinarslan"></input>

    </div>


                                <div className="form-group" style={{paddingBottom:'10px'}}>
                                  <label for="kullanicilabel" className="control-label">Rehber No</label>
                                  <label className="error" for="kullanicifield"></label>
                                  <input id="id_rehberid" name="rehberid" className="form-control" type="text" required="required"
                                    maxlength="100" value={props.id} placeholder="" readonly="readonly" />

                                </div>

                                <div className="form-group" style={{paddingBottom:'10px'}}>
                                  <label for="rehberlabel" className="control-label">Müşteri No</label>
                                  <label className="error" for="rehberfield"></label>
                                  <input name="uyeno" className="form-control" type="text" required="required" maxlength="60"
                                    value={musno} placeholder="" readonly="readonly" />
                                </div>

                                <div className="form-group" style={{paddingBottom:'10px'}}>
                                  <label for="tcverginolabel" className="control-label">{tuzelkisilik ? 'Vergi No' : 'TC Kimlik No'}</label>
                                  <label className="error" for="tcverginofield"></label>
                                  <input id="id_tcvergino" onChange={handleChangeTcVergino} name="tcvergino" className="form-control" type="text" required="required"
                                    minlength="11" maxlength="11" value={tcvergino} placeholder=""
                                    title="Bu bölümde bir sayı bulunmalıdır..." pattern="\d*" />
                                </div>
                                <p></p>

        </form>
    </div>
                    </div>
                </div>
            </div>
            
        </div>

        
        <div className="modal fade" id="kt_modal_new_ticket" tabindex="-1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered mw-750px">
                <div className="modal-content rounded">
                    <div className="modal-header pb-0 border-0 justify-content-end">
                        <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                            <span className="svg-icon svg-icon-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div className="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
                        <form id="kt_modal_new_ticket_form" className="form" action="#">
                            <div className="mb-13 text-center">
                                <h1 className="mb-3">Create Ticket</h1>
                                <div className="text-gray-400 fw-bold fs-5">If you need more info, please check
                                    <a href="" className="fw-bolder link-primary">Support Guidelines</a>.</div>
                            </div>
                            <div className="d-flex flex-column mb-8 fv-row">
                                <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                                    <span className="required">Subject</span>
                                    <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Specify a subject for your issue"></i>
                                </label>
                                <input type="text" className="form-control form-control-solid" placeholder="Enter your ticket subject" name="subject" />
                            </div>
                            <div className="row g-9 mb-8">
                                <div className="col-md-6 fv-row">
                                    <label className="required fs-6 fw-bold mb-2">Product</label>
                                    <select className="form-select form-select-solid" data-control="select2" data-hide-search="true" data-placeholder="Select a product" name="product">
                                        <option value="">Select a product...</option>
                                        <option value="1">HTML Theme</option>
                                        <option value="1">Angular App</option>
                                        <option value="1">Vue App</option>
                                        <option value="1">React Theme</option>
                                        <option value="1">Figma UI Kit</option>
                                        <option value="3">Laravel App</option>
                                        <option value="4">Blazor App</option>
                                        <option value="5">Django App</option>
                                    </select>
                                </div>
                                <div className="col-md-6 fv-row">
                                    <label className="required fs-6 fw-bold mb-2">Assign</label>
                                    <select className="form-select form-select-solid" data-control="select2" data-hide-search="true" data-placeholder="Select a Team Member" name="user">
                                        <option value="">Select a user...</option>
                                        <option value="1">Karina Clark</option>
                                        <option value="2">Robert Doe</option>
                                        <option value="3">Niel Owen</option>
                                        <option value="4">Olivia Wild</option>
                                        <option value="5">Sean Bean</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row g-9 mb-8">
                                <div className="col-md-6 fv-row">
                                    <label className="required fs-6 fw-bold mb-2">Status</label>
                                    <select className="form-select form-select-solid" data-control="select2" data-placeholder="Open" data-hide-search="true">
                                        <option value=""></option>
                                        <option value="1" selected="selected">Open</option>
                                        <option value="2">Pending</option>
                                        <option value="3">Resolved</option>
                                        <option value="3">Closed</option>
                                    </select>
                                </div>
                                <div className="col-md-6 fv-row">
                                    <label className="required fs-6 fw-bold mb-2">Due Date</label>
                                    <div className="position-relative d-flex align-items-center">
                                        <div className="symbol symbol-20px me-4 position-absolute ms-4">
                                            <span className="symbol-label bg-secondary">
                                                <span className="svg-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect x="2" y="2" width="9" height="9" rx="2" fill="black" />
                                                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="black" />
                                                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="black" />
                                                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="black" />
                                                    </svg>
                                                </span>
                                            </span>
                                        </div>
                                        <input className="form-control form-control-solid ps-12" placeholder="Select a date" name="due_date" />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column mb-8 fv-row">
                                <label className="fs-6 fw-bold mb-2">Description</label>
                                <textarea className="form-control form-control-solid" rows="4" name="description" placeholder="Type your ticket description"></textarea>
                            </div>
                            <div className="fv-row mb-8">
                                <label className="fs-6 fw-bold mb-2">Attachments</label>
                                <div className="dropzone" id="kt_modal_create_ticket_attachments">
                                    <div className="dz-message needsclick align-items-center">
                                        <span className="svg-icon svg-icon-3hx svg-icon-primary">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM16 12.6L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L8 12.6H11V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18V12.6H16Z" fill="black" />
                                                <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black" />
                                            </svg>
                                        </span>
                                        <div className="ms-4">
                                            <h3 className="fs-5 fw-bolder text-gray-900 mb-1">Drop files here or click to upload.</h3>
                                            <span className="fw-bold fs-7 text-gray-400">Upload up to 10 files</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-15 fv-row">
                                <div className="d-flex flex-stack">
                                    <div className="fw-bold me-5">
                                        <label className="fs-6">Notifications</label>
                                        <div className="fs-7 text-gray-400">Allow Notifications by Phone or Email</div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <label className="form-check form-check-custom form-check-solid me-10">
                                            <input className="form-check-input h-20px w-20px" type="checkbox" name="notifications[]" value="email" checked="checked" />
                                            <span className="form-check-label fw-bold">Email</span>
                                        </label>
                                        <label className="form-check form-check-custom form-check-solid">
                                            <input className="form-check-input h-20px w-20px" type="checkbox" name="notifications[]" value="phone" />
                                            <span className="form-check-label fw-bold">Phone</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center">
                                <button type="reset" id="kt_modal_new_ticket_cancel" className="btn btn-light me-3">Cancel</button>
                                <button type="submit" id="kt_modal_new_ticket_submit" className="btn btn-primary">
                                    <span className="indicator-label">Submit</span>
                                    <span className="indicator-progress">Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
{/* h */}




</section>      

        

        
     
      </div>

    );

  }

    // if (!navigator.onLine) 
    // return <div>Hata:İnternet bağlantısı yok...</div>;

    if (error) {
      //context.setLoader(false);
      return <div>Bir hata oluştu.</div>;
    }
  
    if (loading) {
  
    }

    //context.setLoader(false);
    return Template();
  
  }

export default RehberDuzenle;


  
