import React, { useContext, useEffect } from 'react';
import AuthContext from '../services/AuthContext';

function PageTitle(props) {

  const context = useContext(AuthContext);

  useEffect(() => {

    return () => {
      //unmount
    }

  }, []);


  return (

    <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0" style={{ marginTop: "6px" }}>
      <a aria-current="page" className="text-muted text-hover-primary" href="/">
        <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1 text-hover-primary">{props.title}</h1>
      </a><span style={{ marginLeft: "10px" }}><span className="badge">{props.count}</span></span>
    </div>

  );

}


export default PageTitle;