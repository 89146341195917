import React, { useContext, useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import $ from 'jquery';
import AuthContext from '../services/AuthContext';
import './BasvuruEkle.css';
import { NavLink } from 'react-router-dom';
import toastr from 'toastr';
import request from '../services/Request';
import baseURL from '../services/baseURL';
import RefreshToken from '../services/RefreshToken';
import CacheItem from '../services/CacheItem';
import Modal from 'react-modal';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import tr from "date-fns/locale/tr"; // the locale you want
registerLocale("tr", tr); // register it with the name you want


const options = {
  placeholder: "Seçiniz",
  language: {
    noResults: function () {
      return "Sonuç bulunamadı...";
    }
  },
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
  },
};

function newtag(params, data) {
  var term = $.trim(params.term);
  if (term === '') {
    return null;
  }
  return {
    id: term,
    text: (term + "  (Yeni)").toLocaleUpperCase('tr-TR'),
    newTag: true // add additional parameters
  }
}

function matchCustom(params, data) {
  // If there are no search terms, return all of the data
  if ($.trim(params.term) === '') {
    return data;
  }

  // Do not display the item if there is no 'text' property
  if (typeof data.text === 'undefined') {
    return null;
  }

  // Return `null` if the term should not be displayed
  return null;
}


// datayı state e al render anında map et
//      this.setState({ loading: false });

  function BasvuruEkle (props) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [html, setHtml] = useState(null);
    const [tags, setTags] = useState(true);
    const [tarih, setTarih] = useState(new Date());
    const [rehber_id, setRehber_id] = useState(-1);
    const [tckimlikvergino, setTckimlikvergino] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [pickerRef, setPickerRef] = useState(new Date());
    const [elemanlar, setElemanlar] = useState(['GERÇEK KİŞİ', 'TÜZEL KİŞİ']);
    const [unvaniadi, setUnvaniadi] = useState("");
    const [musno, setMusno] = useState("");
    const [tcvergino, setTcvergino] = useState("");
    const [tuzelkisilik, setTuzelkisilik] = useState(false);
    const [sehir, setSehir] = useState("...");
    const [ilce, setIlce] = useState("...");
    const [adres, setAdres] = useState("");
    const [telefon, setTelefon] = useState("");
    const [faks, setFaks] = useState("");
    const [gsm, setGsm] = useState("");
    const [email, setEmail] = useState("");
    const [tipi, setTipi] = useState("");
    const [sehirler, setSehirler] = useState([]);
    const [ilceler, setIlceler] = useState([]);

    const context = useContext(AuthContext);

  const handleOpenModal = () => {
    this.setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setTags(true);
  }

  const handleChangeUnvaniAdi = (event) => {
    setUnvaniadi(event.target.value);
  }

  const handleChangeTcVergino = (event) => {
    setTcvergino(event.target.value);
  }

  const Kaydet_Basarili = (response) => {
    
    setLoading(false);

    toastr.options = {
      "positionClass": "toast-top-center",
      "preventDuplicates": true,
    }
    toastr["success"]('Bilgiler Kaydedildi...');
  }

  const Kaydet_Basarisiz = (status) => {
    
    setLoading(false);
    
    toastr.options = {
      "positionClass": "toast-top-center",
      "preventDuplicates": true,
    }
    if (status === 462) {
      toastr["error"]('İşlem başarısız oldu. </br> Bu TC Kimlik / Vergi No zaten kayıtlı...');
    }
    else {
      toastr["error"]('İşlem başarısız oldu');
    }
  }

  const Kaydet = () => {
    var form = document.getElementById('myForm');
    var isValid = form.checkValidity();
    if (isValid) {

      if ($('#zz').val() === "GERÇEK KİŞİ") tuzelkisilik = false; else setTuzelkisilik(true);

      //var sehirid = ($("#mm1")[0].selectedIndex).toString();

      //var ilceadi = $("#nn1 option:selected").text();
      setLoading(true);

      request('POST', '/basvuruekle',
        { 'tarih': tarih, 'tckimlikvergino': tckimlikvergino },
        Kaydet_Basarili, Kaydet_Basarisiz,
        '');
    }
  }


  const IlceDoldur_Basarili = (response) => {

    var ilceler = response.data;

    setIlceler(ilceler);
  }

  const IlceDoldur_Basarisiz = (status) => {
    
    // var ilceler = response.data;

    // this.setState({ ilceler: ilceler });
  }

  const BasvuruEkle_Basarili = (response) => {

    var rehber = response.data;

    setSehirler(rehber.sehir);
    setUnvaniadi(rehber.unvan);
    setTckimlikvergino(rehber.tckimlikvergino);
    IlceYukle("...");

    //alert(response.data);

    // $('#mm1').on("select2:select", function (e) {
    //if (e.params.data.text!="...") this.IlceYukle(e.params.data.text); else this.IlceYukle("...");
    //this.setState({ ilce:"..." });
    //}.bind(this));

    setLoading(false);
  }


  const BasvuruEkle_Basarisiz = (status) => {

  }

  useEffect(() => {

    var ids = props.id;
    // if (ids == undefined) ids = 1;
    // if (ids <= 0) ids = 1;
    // alert(ids)
    var reqdata = { 'id': props.id };

    request('GET', '/basvuruekle', reqdata, BasvuruEkle_Basarili, BasvuruEkle_Basarisiz, '');
     
    return () => {
     //unmount
    }

  }, []);

  const Basarili = (response) => {
  
    // var a=$('.select2-search__field[aria-controls=select2-tt2-results]').val();
    // $('#tt2').empty();

    // var newOption = new Option(a+"  (YENİ)", a, false, false);
    // $('#tt2').append(newOption);

    // response.data.map((val, key) => {
    // newOption = new Option(val, val, false, false);
    // $('#tt2').append(newOption);
    // })


    // $('#select2-results__option').select2('close');
    // $('#select2-results__option').select2('open');
    // $('.select2-search__field[aria-controls=select2-tt2-results]').val(a);
  }

  const Basarisiz = () => {

   
  }


  const formPreventDefault = (e) => {
    e.preventDefault();
  }

  const setTuzelKisilik = (bool) => {

  }

  const kisilikSelected = () => {
    var tuzel = $('#zz1').val() === "TÜZEL KİŞİ"; 

    if (tuzel) {
      setTuzelkisilik(true);
      $("#id_tcvergino").attr('maxlength', '10');
      $("#id_tcvergino").attr('minlength', '10');

    } else {
      setTuzelkisilik(false);
      $("#id_tcvergino").attr('maxlength', '11');
      $("#id_tcvergino").attr('minlength', '11');
    }
    $("#id_tcvergino:text").val("");

  }

  const degerSelected = (e) => {

    var unvan = e.target.value;
    var start = unvan.indexOf("(")
    var end = unvan.indexOf(")")

    setTckimlikvergino(unvan.substring(start + 1, end));

    setUnvaniadi(e.target.value);
  }

  const Acildi = (e) => {
    document.querySelector('.select2-search__field').focus();
  }

  const Kapandi = (e) => {
    if (e.target.value)
      setUnvaniadi(e.target.value); else setUnvaniadi(e.target.value);
  }

  const handleDateChange = (e) => {
    //alert(e.target.value);
  }

  const SetDate = (date) => {
    setTarih(date);
  }

  const sehirSelected = (e) => {
    //alert(e.target.value);

    //this.setState({ sehir: e.target.value, ilce: "..." });
  }

  const ilceSelected = (e) => {
    setIlce(e.target.value); 
  }

  const IlceYukle = (sehir) => {

    request('POST', '/ilcedoldur',
      { "sehiradi": sehir },
      IlceDoldur_Basarili, IlceDoldur_Basarisiz,
      '');
  }

  const Kod = () => {
    return (<button className="btn btn-sm btn-link gbtn" onClick={Kaydet}>Ekle</button>)

  }

  const Template = () => {

    return (

      <div className="BasvuruEkle">

        <div id="carddiv">
          <div id="editcarddiv">

            <section className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-12">
                    <ol className="breadcrumb float-sm-left">
                      <li className="breadcrumb-item"><NavLink to="/basvurular">Başvurular</NavLink></li>
                      {/* <li className="breadcrumb-item"><NavLink to={"/rehber/detay/" + this.props.id}>Detaylar</NavLink></li> */}
                      <li className="breadcrumb-item active">Ekle</li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">

                    <div className="card card-outline">

                      <div className="card-body cbd">
                        <h6 className="card-title"></h6>
                        <form id="myForm" method="post" autocomplete="off" onSubmit={formPreventDefault}>

                          <div className="card-header">

                            <div style={{ width: '100%' }}>
                              <div className="" style={{ marginRight: '30px', float: 'right' }}>

                                <button className="btn btn-sm btn-link gbtn" onClick={Kaydet}>Ekle</button>

                              </div>
                            </div>

                          </div>

                          <Row>
                            <Col sm={6}>

                              <div className="card card-body flex-fill snone">

                                <div className="form-group">
                                  <label for="adilabel" className="control-label">Başvuran</label>
                                  {/* <Select2
                                    id="tt2"
                                    className="form-control"
                                    disabled={unvaniadi ? true : false}
                                    // data={this.state.ilceler}
                                    onSelect={degerSelected}
                                    // onChange={this.degerSelected}
                                    onOpen={Acildi}
                                    onClose={Kapandi}
                                    style={{ width: "100%" }}
                                    defaultValue={unvaniadi}
                                    options={{
                                      placeholder: unvaniadi ? unvaniadi : "Seçiniz",
                                      minimumInputLength: 1,
                                      language: {
                                        noResults: function () {
                                          //return ""
                                          return $('<a id="rehberekle" href="#" class="btn btn-sm btn-link">Rehberde Bulunamadı => Ekle</a>')

                                        },
                                        errorLoading: function () {
                                          return "Sonuç bulunamadı...";
                                        },
                                        searching: function () {
                                          return "Aranıyor...";
                                        },
                                        inputTooShort: function (args) {
                                          // $("#tt2").empty();

                                          var remainingChars = args.minimum - args.input.length;

                                          var message = 'En az ' + remainingChars + ' karakter daha girmelisiniz';

                                          return message;
                                        },
                                      },
                                      width: 'resolve',


                                      ajax: {
                                        url: baseURL() + "/adiunvaniaratcli",
                                        type: "POST",
                                        datatype: "json",
                                        headers: {
                                          "Authorization": 'Bearer ' + localStorage.token
                                        },
                                        beforeSend: function (xhr) {
                                          RefreshToken(this);
                                          xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.token);
                                        },
                                        data: function (params) {
                                          return {
                                            term: params.term,
                                          };
                                        },
                                        processResults: function (data) {
                                          //$("#tt1").empty();
                                          return {
                                            results: $.map(data, function (i, item) {
                                              return {
                                                //text: i.length > 20 ? i.substr(0, 20) + '…' : i,
                                                text: i,
                                                id: i,//item
                                              }
                                            })

                                          };
                                        },
                                        error: function (jqXHR, textStatus, errorThrown) {
                                          if (jqXHR.status == 401) {
                                            //RefreshToken(this);
                                          }
                                        },

                                      }
                                    }}
                                    required /> */}

                                </div>




                              </div>
                            </Col>
                            <Col sm={6}>

                              <div className="card  card-body flex-fill snone" >

                                <div className="form-group">
                                  <label for="tarihlabel" className="control-label">Tarih</label>
                                  <DatePicker
                                    dateFormat="dd.MM.yyyy"
                                    selected={tarih}
                                    //onChange={this.state.startDate}
                                    locale="tr"
                                   // selected={selectedDate}
                                    onChange={SetDate} 
                                    ref={pickerRef}
                                    className="form-control"
                                  />
                                </div>




                              </div>

                            </Col>
                          </Row>

                        </form>
                      </div>

                    </div>

                  </div>
                </div>

              </div>

            </section>

          </div>
        </div>
      </div>

    );

  }



  if (error) {
    context.setLoader(false);
    return <div>Bir hata oluştu.</div>;
  }

  if (loading) {

    if (!context.HTML) {
      return <div className="loaderdiv"></div>;
    }
    else {
      context.setLoader(true);
      return context.HTML;
    }
  }

  context.HTML = Template(); 
  context.setLoader(false);
  return context.HTML;
}

 export default BasvuruEkle;