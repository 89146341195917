import toastr from 'toastr';

const showSuccessmessage = (m) => {
    toastr.options = {
      "positionClass": "toast-top-center",
      "preventDuplicates": true,
    }

    toastr["success"](m);
  }
 
  const showErrormessage = (e) => {
    toastr.options = {
      "positionClass": "toast-top-center",
      "preventDuplicates": true,
    }

    toastr["error"](e);
  }



  export { showSuccessmessage, showErrormessage  };