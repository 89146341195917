import React from 'react';
import ReactDOM from 'react-dom';
// import 'jquery/dist/jquery.min.js';
// import 'admin-lte/dist/js/adminlte.min.js'
// import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';


ReactDOM.render(
   <React.StrictMode>
    <BrowserRouter>
    <App />
   </BrowserRouter>
  </React.StrictMode>,
  
  document.getElementById('root')
  
)
