import React from 'react';
import baseURL from '../services/baseURL';
import './SifremiUnuttum.css';
import AuthContext from '../services/AuthContext';
import axios from 'axios';
import refresh_token from '../services/AuthService';
import qs from 'qs';

class SifremiUnuttum extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = { email: '' };
        this.PostData = this.PostData.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.Basarili = this.Basarili.bind(this);
    }

    componentDidMount() {

        if (this.context.isLoggedIn) {
            this.context.User='';
            this.context.Company='';
            localStorage.clear();
        }

    }
    
    Basarili(response)
    {
        window.location = '/sifre-gonderildi';
    }
    
    PostData() {
        var form = document.getElementById('myForm');
        var isValid = form.checkValidity();
        if (isValid) {

            axios.request({
                method: 'POST',
                url: baseURL() + '/forpas',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                data:qs.stringify({'email': this.state.email}),
            
            }).then((response) => {
                this.Basarili(response);
            },
                (error) => {
                    var status = error.response.status
                    if (status === 401) {
                        var msg='';
                        refresh_token(error.config,msg,this.Basarili);
                    }
                }
            );
        }
    }
    handleChangeEmail(event) {
        this.setState({ email: event.target.value });
    }
    formPreventDefault(e) {
        e.preventDefault();
    }
    render() {
        return (

            <div className="col-12 d-flex align-items-center justify-content-center top">
                <div className="login-box">
                    <div className="login-logo">

                    </div>

                    <div className="card">

                        <div className="card-body">
                            <p className="login-box-msg"></p>

                            <form id="myForm" method="post" onSubmit={this.formPreventDefault}>

                                <div className="input-group mb-3">
                                    <input id="id_email" type="email" className="form-control" name="email" value={this.state.email} onChange={this.handleChangeEmail} title="" placeholder="E-Mail" required="required" minlength="5" maxlength="50" />

                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-envelope"></span>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-8">
                                        <div className="icheck-primary">
                                            <div id="hatadiv"><p>Hata Oluştu...</p></div>
                                        </div>
                                    </div>

                                    <div className="col-12"></div>
                                    <button type="submit" onClick={this.PostData} className="btn btn-secondary btn-block">Şifremi Sıfırla</button>

                                </div>

                            </form>

                        </div>

                    </div>
                </div>
            </div>

        );

    }
}

export default SifremiUnuttum;