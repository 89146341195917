import React,{ useContext, useState, useEffect } from "react";
import "react-tagsinput/react-tagsinput.css";
//import "./Rehber.css";
import RehberCard from "./RehberCard";
import AuthContext from '../../services/AuthContext';

function Rehber() {
  const [error, setError] = useState(false);

	const context = useContext(AuthContext);

  useEffect(() => {

},[]);

  if (error) {
    return <div>Bir hata oluştu.</div>;
  }

  return (

    <div>
      <RehberCard />
    </div>
  );
}

export default Rehber;
