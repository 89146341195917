import React from 'react';
import './SifreGonderildi.css';

function SifreGonderildi() {
    return (
        <div className="contentpd">

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">

                            <div className="card">

                                <div className="card-body">
                                    <h6 className="card-title"></h6>

                                    <h4><i className="icon fa fa-check"></i> Parola sıfırlama bilgileri gönderildi</h4>
                                    <p>Eğer girdiğiniz e-posta ile bağlantılı bir hesabınız varsa, parolanızın ayarlanması için size talimatları e-posta ile gönderdik. En kısa sürede almalısınız.
        Eğer bir e-posta almadıysanız, lütfen kayıt olurken girdiğiniz adresi kullandığınızdan emin olun ve istenmeyen mesajlar klasörünü kontrol edin.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </section>
        </div>
    );
}

export default SifreGonderildi;