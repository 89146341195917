import React from "react";
import baseURL from "../services/baseURL";
import $, { param } from "jquery";
import RefreshToken from "../services/RefreshToken";
import "./OturumAc.css";
import AuthContext from "../services/AuthContext";

class OturumAc extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = { username: "", password: "", param: "" };

    this.ButtonClick = this.ButtonClick.bind(this);
    this.handleChangeUserName = this.handleChangeUserName.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.myInput = React.createRef(); // Ref oluştur
  }

  componentDidMount() {
    this.context.userName = "";
    this.context.Company = "";
    localStorage.clear();

    // var next = this.props.next ;
    //window.location.href = '/'+next;
    this.state.param = this.props.id;
    if (this.props.id != undefined)
      this.state.param = this.state.param.replaceAll("-", "/");
    // alert(this.state.param);
    $("#kt_wrapper").css('padding-left', '0');
    //document.getElementsByClassName("OturumAc")[0].style.display = 'block';
    setTimeout(() => {
      $(".OturumAc").show();  
    }, "100")
     //alert("oha");
     //$(".OturumAc").show();  
   
// Replace the validation UI for all forms
var forms = document.querySelectorAll( "form" );
for ( var i = 0; i < forms.length; i++ ) {
    this.replaceValidationUI( forms[ i ] );
}

setTimeout(() => {
  this.myInput.current.focus();
}, 100);
}

  Bilgilerim() {
    $.ajax({
      type: "GET",
      url: baseURL() + "/bilgilerim",
      headers: {
        Authorization: "Bearer " + localStorage.token,
      },
      success: function (bilgi) {
        localStorage.userName = bilgi.adi + " " + bilgi.soyadi;
        localStorage.company = bilgi.firma;
        if (this.state.param != undefined)
          window.location.href = "/" + this.state.param;
        else window.location.href = "/";
        //context.partial('/components/hesabim.html', { 'username': bilgi.username, 'firma': bilgi.firma, 'adi': bilgi.adi, 'soyadi': bilgi.soyadi });
      }.bind(this),
      //
      error: function (jqXHR, textStatus, errorThrown) {
        if (jqXHR.status === 401) {
          RefreshToken(this);
        }
      },
      //
    });

  }


  replaceValidationUI( form ) {
    // Suppress the default bubbles
    form.addEventListener( "invalid", function( event ) {
        event.preventDefault();
    }, true );

    // Support Safari, iOS Safari, and the Android browser—each of which do not prevent
    // form submissions by default
    form.addEventListener( "submit", function( event ) {
        if ( !this.checkValidity() ) {
            event.preventDefault();
        }
    });

    var submitButton = form.querySelector( "button:not([type=button]), input[type=submit]" );
    submitButton.addEventListener( "click", function( event ) {
        var invalidFields = form.querySelectorAll( ":invalid" ),
            errorMessages = form.querySelectorAll( ".error-message" ),
            parent;

        // Remove any existing messages
        for ( var i = 0; i < errorMessages.length; i++ ) {
            errorMessages[ i ].parentNode.removeChild( errorMessages[ i ] );
        }

        for ( var i = 0; i < invalidFields.length; i++ ) {
            parent = invalidFields[ i ].parentNode;
            parent.insertAdjacentHTML( "beforeend", "<div class='error-message'>" + 
                invalidFields[ i ].validationMessage +
                "</div>" );
        }

        // If there are errors, give focus to the first invalid field
        if ( invalidFields.length > 0 ) {
            invalidFields[ 0 ].focus();
        }
    });

  }



  ButtonClick() {

    var form = document.getElementById("myForm");
    var isValid = form.checkValidity();
    if (isValid) {
      $.ajax({
        type: "POST",
        url: baseURL() + "/login",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          email: this.state.username,
          password: this.state.password,
        }),
        success: function (data) {
         // alert(JSON.stringify(data.aToken));
          localStorage.token = data.aToken;
          localStorage.refresh = data.rToken;

          localStorage.userName = this.state.username;
          localStorage.name = data.adi + " " + data.soyadi;
          localStorage.rol = data.rol;
          localStorage.firma = data.firma;
          //localStorage.company = bilgi.firma;
          if (this.state.param != undefined)
            window.location.href = "/" + this.state.param;
          else window.location.href = "/";
          //this.Bilgilerim();

          //localStorage.tenantid = 1;
          //window.location.href = '/';
        }.bind(this),
        error: function (jqXHR, textStatus, errorThrown) {
          if (jqXHR.status === 401) {
            $("#hatadiv").show();
          }
        },
      });
    }
  }
  handleChangeUserName(event) {
    this.setState({ username: event.target.value });
  }
  handleChangePassword(event) {
    this.setState({ password: event.target.value });
  }
  formPreventDefault(e) {
    e.preventDefault();
  }
  


  render() {
    return (
      <div className="OturumAc" style={{display:"none"}}>
      <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">

          <div className="w-lg-400px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">

            <form
              id="myForm"
              autoComplete="off"
              method="post"
              onSubmit={this.formPreventDefault}
             > 
             <div className="text-center mb-10">
                <h1 className="mb-3"><img alt="Logo" src="http://front.alfacert.com.tr/alfalogo48.png" className="h-40px" />&nbsp;AlfaCERT</h1>
              </div>
              <div className="fv-row mb-10">
                <label className="form-label fs-6 fw-bolder text-dark">Email</label>
                <input className="form-control form-control-lg form-control-solid error" ref={this.myInput} value={this.state.username}
                  onChange={this.handleChangeUserName} type="email" required="required"
                  minLength="5" maxLength="50" name="email" autoComplete="off" />
                </div>
              <div className="fv-row mb-10">
                <div className="d-flex flex-stack mb-2">
                  <label className="form-label fw-bolder text-dark fs-6 mb-0">Parola</label>
                  <a href="/sifremi-unuttum" className="link-primary fs-6 fw-bolder">Şifremi Unuttum</a>
                </div>
                <input className="form-control form-control-lg form-control-solid" value={this.state.password}
                  onChange={this.handleChangePassword} type="password" required="required"
                  minLength="5" maxLength="50" name="password" autoComplete="off" />    
          </div>

                  <div id="hatadiv">
                        <p></p>
                        <p>Kullanıcı adı veya parola hatalı...</p>
                      </div>
              <div className="text-center">
                <button onClick={this.ButtonClick} id="kt_sign_in_submit" className="btn btn-lg btn-primary w-100 mb-5">
                  <span className="indicator-label">Oturum Aç</span>
                  <span className="indicator-progress">Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                </button>
              </div>
            </form>
          </div>
        </div>

      </div>
      </div>
    );
  }
}

export default OturumAc;
