import React, { useContext, useState, useEffect, useRef } from 'react';
//import './Hesabim.css';
import AuthContext from '../services/AuthContext';
import { showSuccessmessage, showErrormessage } from '../services/Messages.js';
import api from '../services/Api';
import { useQuery, useMutation, QueryClient, QueryClientProvider } from "react-query";

function HesabimDegistirCard() {
    const context = useContext(AuthContext);
    return (
        <QueryClientProvider client={context.queryClient}>
            <HesabimDegistirCard2 />
        </QueryClientProvider>
    )
}

function HesabimDegistirCard2() {
    const context = useContext(AuthContext);
    const queryClient = context.queryClient;
    const [error, setError] = useState(null);

    const postHesabimData = () => {
        return api.post(`/bilgilerim`, { 'adi': data.adi, 'soyadi': data.soyadi });
    };

    function isOnlyWhitespace(str) {
        return str.trim().length === 0;
    }

    const { mutate } = useMutation(postHesabimData, {
        onSuccess: () => {
            queryClient.invalidateQueries("hesapQueryClient");
            if (isOnlyWhitespace(data.adi)) MediaStreamAudioDestinationNode() 
            
            
            localStorage.name = data.adi + " " + data.soyadi;
            context.setName(data.adi + ' ' + data.soyadi);
            document.getElementById("kt_button_1").removeAttribute("data-kt-indicator");
            //context.hideButtonloader('mbutton2');
            showSuccessmessage('Bilgileriniz Kaydedildi...');
        },
        onError: () => {
            document.getElementById("kt_button_1").removeAttribute("data-kt-indicator");
            showErrormessage('İşlem başarısız oldu');
        }
    });

    
    const BilgileriKaydet = (e) => {
        // Replace the validation UI for all forms
        if (isOnlyWhitespace(document.getElementById("id_ad").value)) {
            document.getElementById("id_ad").value=""
            document.getElementById("id_ad").focus();
        };
        
        if (isOnlyWhitespace(document.getElementById("id_soyad").value)) {
            document.getElementById("id_soyad").value=""
            document.getElementById("id_soyad").focus();
        };
        
        var form = document.getElementById('myForm');

        var isValid = form.checkValidity();
        if (isValid) {

            //setLoading(true);
            document.getElementById("kt_button_1").setAttribute("data-kt-indicator", "on");
            
            mutate();
        }
    }
    const fetchHesabimData = async () => {
        const { data } = await api.get('/bilgilerim');

        return data;
    };

    const { isLoading, isFetching, isError, status, refetch,data } = useQuery('hesapQueryClient', fetchHesabimData, {
            onError: (error) => {

                setError(error);

            },
        });

        const replaceValidationUI = (form) => {
            // Suppress the default bubbles
            form.addEventListener("invalid", function (event) {
                event.preventDefault();
            }, true);
    
            // Support Safari, iOS Safari, and the Android browser—each of which do not prevent
            // form submissions by default
            form.addEventListener("submit", function (event) {
                if (!this.checkValidity()) {
                    event.preventDefault();
                }
            });
    
            var submitButton = form.querySelector("button:not([type=button]), input[type=submit]");
    
            if (submitButton) {
            submitButton.addEventListener("click", function (event) {
                var invalidFields = form.querySelectorAll(":invalid"),
                    errorMessages = form.querySelectorAll(".error-message"),
                    parent;
    
                // Remove any existing messages
                for (var i = 0; i < errorMessages.length; i++) {
                    errorMessages[i].parentNode.removeChild(errorMessages[i]);
                }
    
                for (var i = 0; i < invalidFields.length; i++) {
                    parent = invalidFields[i].parentNode;
                    if (invalidFields[i].validationMessage.search("eşleştirin") > -1) {
    
                        parent.insertAdjacentHTML("beforeend", "<div class='error-message'>" +
                            "En az bir rakam, bir büyük ve küçük harf ve en az 8 veya daha fazla karakter içermelidir." +
                            "</div>");
                    }
                    else {
                        parent.insertAdjacentHTML("beforeend", "<div class='error-message'>" +
                            invalidFields[i].validationMessage +
                            "</div>");
                    }
                }
    
                // If there are errors, give focus to the first invalid field
                if (invalidFields.length > 0) {
                    invalidFields[0].focus();
                }
            });
        }
        }

    useEffect(() => {
       
        //request('GET', '/bilgilerim', "", BilgilerimiAl_Basarili, BilgilerimiAl_Basarisiz, '');

        var form = document.getElementById('myForm');
        if (form) replaceValidationUI(form);

        return () => {

        };

    });


    const formPreventDefault = (e) => {
        e.preventDefault();
    }


    var forms = document.querySelectorAll("form");
    for (var i = 0; i < forms.length; i++) {
        replaceValidationUI(forms[i]);
    }


    if (error) if (error.response.status != "402") {
        return (

            <div id="loadericondiv2" style={{ display: 'block', color: 'lightgray', fontSize: '15px', marginTop: '0px' }} className="float-left">
           <div>Bir hata oluştu. {error.response.status}</div>
       </div>
            );
    }
    else {
        return (

            <div id="loadericondiv2" style={{ display: 'block', color: 'lightgray', fontSize: '15px', marginTop: '0px' }} className="float-left">
             <div>Bu bölümü görüntüleme yetkiniz yok. {error.response.status}</div>
        </div>
           
        )
    };

    return (

        !isLoading ?

            <div id="info" className="card  me-xl-3 mb-md-0 mb-6">
                <form id="myForm" method="post" autocomplete="off" onSubmit={formPreventDefault}>

                    <div className="card-header">

                        <div className="card-title">
                            <h4 className="fw-bolder">Bilgilerim</h4>
                        </div>

                        <div className="card-toolbar">

                            <button id="kt_button_1" onClick={(e) => BilgileriKaydet(e.target)} className="btn btn-primary btn-sm">
                                <span className="indicator-label">
                                    Kaydet
                                </span>
                                <span className="indicator-progress">
                                    Kaydet<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>

                    </div>

                    <div className="card-body">

                        <div className="d-flex flex-wrap py-5">

                            <div className="form-group mdv" style={{ width: "100%" }}>
                                <label for="adilabel" className="required fs-6 fw-bold mb-2 control-label">Adı</label>
                                <label className="error" for="adifield"></label>
                                <input id="id_ad" name="ad" className="form-control form-control-solid" type="text" spellcheck="false" required="required" maxlength="100"
                                    defaultValue={data.adi} onChange={e => data.adi = e.target.value} placeholder="" />
                            </div>

                            <div className="form-group mdv" style={{ width: "100%" }}>
                                <label for="soyadilabel" className="required fs-6 fw-bold mb-2 control-label">Soyadı</label>
                                <label className="error" for="soyadifield"></label>
                                <input id="id_soyad" name="soyad" className="form-control form-control-solid" type="text" spellcheck="false" required="required"
                                    maxlength="100" defaultValue={data.soyadi} onChange={e => data.soyadi = e.target.value} placeholder="" />
                            </div>

                        </div>
                    </div>
                </form>
            </div> : <div>
                <div className="child">

                    <div id="loadericondiv2" style={{ display: 'block', color: 'lightgray', fontSize: '50px', marginTop: '0px' }} className="float-left">
                        <i id="loadericon" className="fas fa-sync fa-spin"></i>
                    </div>

                </div>
            </div>

    );

}

export default HesabimDegistirCard;