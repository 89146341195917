import React, { useContext, useState, useEffect } from 'react';
//import jquery from 'jquery';
import { NavLink } from "react-router-dom";
//import './Basvurular.css';
import BasvurularCard from "./BasvurularCard";
//import AuthContext from '../../services/AuthContext';
import Context from '../../context/store'

function Basvurular() {
  const {state, dispatch} = useContext(Context);
  const [error, setError] = useState(false);
  //const context = useContext(AuthContext);
  
  useEffect(() => {

    dispatch({type:"SET_CURRENTPAGENAME",payload:"Başvurular"})

    dispatch({type:"SET_CURRENTSUBPAGENAME",payload:""})

    dispatch({type:"SET_CURRENTRECCOUNT",payload:-1})

    return () => {
     //unmount
    }
  
  }, []);
  if (error) {
    return <div>Bir hata oluştu.</div>;
  }

 return (

    <div className="Basvurular">

      <div id="carddiv" style={{ display: 'block' }}>

        <section className="content-header">
          <div className="container-fluid">

          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
              <BasvurularCard />
              </div>

            </div>
          </div>

        </section>

      </div>
      
    </div>

  );

}

export default Basvurular; 