import React, {useContext, useEffect} from 'react';
import './Header.css';
import $ from 'jquery';
import { NavLink,Link } from 'react-router-dom';
//import AuthContext from '../services/AuthContext';
import LoggedIn from '../services/LoggedIn';
import Context from '../context/store'

function Header() {

  const {state, dispatch} = useContext(Context);
 // const { language, setLanguage } = useContext(AuthContext);
  // constructor(props) {
  //   super(props);
  // }
  useEffect(() => {
    $("#kt_aside_mobile_toggle").on('click', () => {

      var has = $("#kt_side").hasClass('drawer drawer-start drawer-on');
      if (!has) {
        setTimeout(function () {
          $("#kt_aside").addClass("drawer drawer-start drawer-on");
          //$("#kt_body").removeAttr("data-kt-aside-minimize");
          //$("#kt_aside_toggle").removeClass("active");
        }, 100);
      }
      else {
        setTimeout(function () {
          $("#kt_aside").removeClass("drawer drawer-start drawer-on");
        }, 100);
      }
    });
    return () => {
      //unmount
    }

  }, []);

    if (LoggedIn()) {

      return (

        <div id="kt_header" className="header align-items-stretch">
          <div className="container-fluid d-flex align-items-stretch justify-content-between">
            <div className="d-flex align-items-center d-lg-none ms-n3 me-1" title="Show aside menu">
              <div className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px" id="kt_aside_mobile_toggle">
                <span className="svg-icon svg-icon-2x mt-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="black" />
                    <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="black" />
                  </svg>
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
              <a href="../../demo1/dist/index.html" className="d-lg-none">
                <img alt="Logo" src="/alfalogo48.png" className="h-30px" />
              </a>
            </div>
            <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
              <div className="d-flex align-items-stretch" id="kt_header_nav">

              <div className="d-flex align-items-center" id="kt_header_nav">
							
									<div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
								
										
                    <NavLink to="/#" className="text-muted text-hover-primary"><h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1 text-hover-primary"> {state.currentPageName}
                    <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
								
						
		
								
								</div>
               
                    </h1>


                    
                    </NavLink>
                    {state.currentRecCount==-1 ? 
                    <>
                    <span className="h-20px border-gray-200 border-start mx-4"></span>
						
            <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
        
              <li className="breadcrumb-item text-muted">
                {state.currentSubPageName}
              </li>

        
            </ul></>: null
            }

								     <span style={{marginLeft:'10px'}}>
                    { state.currentRecCount!=-1 ? 
                    (<span className="badge">{state.currentRecCount}</span>): null
                    }
                    </span>
								
										{/* <span class="h-20px border-gray-200 border-start mx-4"></span>
									
										<ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
										
						
									
					
											<li class="breadcrumb-item text-dark">Ekle</li>
										
										</ul> */}
										
									</div>
						
								</div>


              </div>

   
              <div className="d-flex align-items-stretch flex-shrink-0">

              <div className="dropdown d-flex align-items-center ms-1 ms-lg-3" id="kt_header_user_menu_toggle">
        Merhaba,&nbsp;&nbsp;<div className="cursor-pointer text-hover-primary" data-kt-menu-trigger="{default: 'click', lg: 'click'}" data-kt-menu-placement="bottom-end">
      <b>{state.name}</b>
        </div>
        
<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px" data-kt-menu="true">

    <div className="menu-item px-5">
        <Link to="/hesabim" className="menu-link px-5">
        <i className="nav-icon fas fa-unlock">&nbsp;&nbsp;&nbsp;</i>
            <span className="menu-text">Hesabım</span>
        </Link>
    </div>
    

    <div className="separator my-2"></div>

    <div className="menu-item px-5">
        <Link to="/oturumu-kapat" className="menu-link px-5">
        <i className="nav-icon fas fa-power-off">&nbsp;&nbsp;&nbsp;</i>
            <span className="menu-text">Oturumu Kapat</span>
        </Link>
    </div>
</div>
    </div>
              </div>

            </div>
          </div>
          
        </div>

      );

    }

    return (

      <nav className="main-header navbar navbar-expand navbar-white navbar-light fixed">

        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i id="mbutton" className="fas fa-bars"></i></a>
          </li>

        </ul>

        <ul className="navbar-nav ml-auto">

          <ul className="nav navbar-nav float-right">

          </ul>

        </ul>
      </nav>

    );

  }


export default Header;