import React, {useState} from "react";
import LoggedIn from '../services/LoggedIn'
import $ from 'jquery';import { useQuery,QueryClient, QueryClientProvider } from "react-query";
//const queryClient = new QueryClient()

export const initialState = {
     userName:localStorage.userName,
     name:localStorage.name,
     rehberFilter:[],
     basvurularFilter:[],
     queryClient:new QueryClient(),
     currentPageName:"",
     currentSubPageName:"",
     currentRecCount:-1,
     rehberFilterInputs:[],
     filterData:[],
     //recordsTotal:-1,
     //currentRecCount:-1,

     company: localStorage.company, 
     // BasvurularFilter: [],
     HTML: null,
     DATA: null,
     Loader: false,
     //RehberDetaySpos: {pos1:0,pos2:0},
     isLoggedIn: LoggedIn(),
     cache: [],
     getrehberdata_postparam: {},
     rehberdetay_postparam: {},
     //getparam:"",
     cacheEnabled: false,

};

const Context = React.createContext(initialState);
export default Context;