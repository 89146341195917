import baseURL from './baseURL';
import refresh_token from './AuthService';
import axios from 'axios';
//import jwt_decode from "jwt-decode"; 

// const isTokenExpired = () => {
//     const decoded = jwt_decode(localStorage.token)
//     //console.log(decoded.exp+"----"+Date.now()/1000);
//     return decoded.exp < (Date.now()/ 1000)
// }

// const rt = async () => {
//     try {
//         const response = await axios.post(baseURL() + '/refreshtoken',{'refresh': localStorage.refresh});
//         localStorage.token = response.data.access;
//         localStorage.refresh = response.data.refresh;
//         console.log(response.data);
//     } catch (err) {
//         console.error(err);
//     }
// };

// axios.interceptors.request.use(async (req) => {
//     if(isTokenExpired()){
//         await rt()
//     }
// })

function request(method,url,data,succesfunc,errorfunc,msg) {
  // if (!isTokenExpired()) console.log("token geçerli"); else console.log("token geçer siz...");
    if (method==='POST') {
    axios.request({
        method: method,
       // maxBodyLength: 209715200, //200mb
       // maxContentLength: 209715200, //200mb
        url: baseURL() + url,
        headers: {
            "Authorization": 'Bearer ' + localStorage.token,
        },
        data: data,

    }).then((response) => {
        succesfunc(response);
    },
        
    (error) => {
        var status = null;

        if (error.response) {
        status = error.response.status
        if (status === 401) {
            refresh_token(error.config, msg, succesfunc, errorfunc);
        }
        else
        {
        errorfunc(status);
        };

        } else if (error.request) {
            errorfunc(null);
        } else {
            errorfunc(null);
        }
    }
   
    );
}
if (method==='GET') {
    axios.request({
        method: method,
        url: baseURL() + url,
        headers: {
            "Authorization": 'Bearer ' + localStorage.token,
        },
        params: data,

    }).then((response) => {
        succesfunc(response);
    },
        (error) => {
            var status = null;

            if (error.response) {
            status = error.response.status
            
            if (status === 401) {
                refresh_token(error.config, msg, succesfunc, errorfunc);
            }
            else
            {
            errorfunc(status);
            };

            } else if (error.request) {
                errorfunc(null);
            } else {
                errorfunc(null);
            }
        }
    );

}
}

export default request;
