//import 'bootstrap';
//import "line-awesome/dist/line-awesome/css/line-awesome.css"
//import "admin-lte/dist/css/adminlte.min.css"
// import 'select2';
// import 'select2/dist/css/select2.css';
import './App.css';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Rehber from './components/Rehber/Rehber';
import Basvurular from './components/Basvurular/Basvurular';
import Teklifler from './components/Teklifler';
import Projeler from './components/Projeler';
import OturumAc from './components/OturumAc';
import Hesabim from './components/Hesabim';
import OturumuKapat from './services/OturumuKapat';
import Reset from './components/Reset';
import LoggedIn from './services/LoggedIn';
import RehberEkle from './components/RehberEkle';
import RehberDetay from './components/RehberDetay';
import RehberDosyalar from './components/RehberDosyalar';
import RehberDuzenle from './components/RehberDuzenle';
import BasvuruEkle from './components/BasvuruEkle';
//import AuthContext, {AuthContextProvider} from './services/AuthContext';
import Footer from "./components/Footer";
import SifremiUnuttum from "./components/SifremiUnuttum";
import SifreGonderildi from "./components/SifreGonderildi";
import RegisterProducer from "./components/RegisterProducer"
import $ from 'jquery';
import Provider from './context/provider';
import QueryProvider from './QueryProvider';

const hideButtonloader = (buttonId) => {
  var el = $('#' + buttonId);
  el.removeClass('fa fa-spinner fa-spin');
}

function App() {
    return (
      <Router>
        <div className="App">
          <div className=""></div>
          <Provider>
          <QueryProvider>
         
          {LoggedIn()? <Header/>:null}
          {LoggedIn()? <Sidebar />:null}
            
              <Switch>
                <Route exact path="/rehber/detay/:id" render={({ match }) => (
                  LoggedIn() ? <RehberDetay id={match.params.id} /> : <Redirect to={"/oturum-ac/next/rehber-detay-" + match.params.id} />
                )} />
                <Route exact path="/rehber/dosyalar/:id" render={({ match }) => (
                  LoggedIn() ? <RehberDosyalar id={match.params.id} /> : <Redirect to={"/oturum-ac/next/rehber-dosyalar-" + match.params.id} />
                )} />
                <Route exact path="/rehber/ekle" render={() => (
                  LoggedIn() ? <RehberEkle />:<Redirect to={"/oturum-ac/next/rehber-ekle"} />
                )} />
                <Route exact path="/rehber/duzenle/:id" render={({ match }) => (
                  LoggedIn() ? <RehberDuzenle id={match.params.id} /> : <Redirect to={"/oturum-ac/next/rehber-duzenle-" + match.params.id} />
                )} />
                <Route exact path="/rehber/filtre/:id" render={({ match }) => (
                  LoggedIn() ? <Rehber id={match.params.id} /> : <Redirect to={"/oturum-ac/next/rehber-sayfa-" + match.params.id} />
                )} />
                <Route exact path="/rehber/sayfa/:id" render={({ match }) => (
                  LoggedIn() ? <Rehber id={match.params.id} /> : <Redirect to={"/oturum-ac/next/rehber-sayfa-" + match.params.id} />
                )} />
                <Route path="/rehber">
                  {LoggedIn() ? <Rehber /> : <Redirect to={"/oturum-ac/next/rehber"} />}
                </Route>
                <Route exact path="/basvurular/ekle/:id" render={({ match }) => (
                  LoggedIn() ? <BasvuruEkle id={match.params.id} /> : <Redirect to={"/oturum-ac/next/rehber-duzenle-" + match.params.id} />
                )} />
                <Route exact path="/basvurular/ekle" render={() => (
                  LoggedIn() ? <BasvuruEkle /> : <Redirect to={"/oturum-ac/next/basvurular-ekle"} />
                )} />
                <Route path="/basvurular">
                  {LoggedIn() ? <Basvurular /> : <Redirect to={"/oturum-ac/next/basvurular"} />}
                </Route>
                <Route path="/teklifler">
                  {LoggedIn() ? <Teklifler /> : <Redirect to={"/oturum-ac/next/teklifler"} />}
                </Route>
                <Route path="/projeler">
                  {LoggedIn() ? <Projeler /> : <Redirect to={"/oturum-ac/next/projeler"} />}
                </Route>
                <Route path="/hesabim">
                  {LoggedIn() ? <Hesabim /> : <Redirect to={"/oturum-ac/next/hesabim"} />}
                </Route>
                <Route path="/oturumu-kapat">
                  <OturumuKapat />
                </Route>
                <Route path="/register-producer">
                  <RegisterProducer />
                </Route>
                <Route path="/sifremi-unuttum">
                  <SifremiUnuttum />
                </Route>
                <Route path="/sifre-gonderildi">
                  <SifreGonderildi />
                </Route>
                <Route path="/reset">
                  <Reset />
                </Route>
                <Route exact path="/oturum-ac/next/:id" render={({ match }) => (
                  <OturumAc id={match.params.id} />
                )} />
                <Route path="/oturum-ac">
                  {LoggedIn() ? <Redirect to={"/"} /> : <OturumAc />}
                </Route>
                <Route render={() => (<Redirect to={"/rehber"} />)} />
              </Switch>
      
            <Footer />
       
          </QueryProvider>
          </Provider>
        </div>
      </Router>
    );
  }

export default App;
