export function reducer(state, action) {
    switch (action.type) {
        case "SET_USERNAME":
            return {
                ...state,  //state' in bir kopyasını oluştur oluştur ve userName' i degistir
                userName: action.payload
            };

        case "SET_NAME":
            return {
                ...state,
                name: action.payload
            };

        case "SET_REHBERFILTER":
            return {
                ...state,
                rehberFilter: action.payload
            };

        case "SET_BASVURULARFILTER":
            return {
                ...state,
                basvurularFilter: action.payload
            };

        case "SET_QUERYCLIENT":
            return {
                ...state,
                queryClient: action.payload
            };
        case "SET_CURRENTPAGENAME":
            return {
                ...state,
                currentPageName: action.payload
            };

        case "SET_CURRENTSUBPAGENAME":
            return {
                ...state,
                currentSubPageName: action.payload
            };
        case "SET_CURRENTRECCOUNT":
            return {
                ...state,
                currentRecCount: action.payload
            };
        case "SET_REHBERFILTERINPUTS":
            return {
                ...state,
                rehberFilterInputs: action.payload
            };
        case "SET_FILTERDATA":
            return {
                ...state,
                filterData: action.payload
            };

        default:
            return state
    }
}