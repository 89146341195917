import React from "react";
import  { initialState } from "./context/store"
import { QueryClientProvider } from "react-query";

const QueryProvider = ({ children }) => {
    return (
        <QueryClientProvider client={initialState.queryClient}>
            {children}
        </QueryClientProvider>

    )

}

export default QueryProvider;