import React from 'react';
import  { NavLink }  from 'react-router-dom';
import AuthContext from '../services/AuthContext';
import $ from 'jquery';
import LoggedIn from '../services/LoggedIn';
//import './Sidebar.css';

class Sidebar extends React.Component {
static contextType = AuthContext;

  // constructor(props) {
  //     super(props);
  // }

  componentDidMount = () => {

     $("#kt_aside_toggle").on('click', () => {
		var attr = $("#kt_body").attr('data-kt-aside-minimize');
		if (typeof attr !== 'undefined' && attr !== false) {
		setTimeout(function() { 
		$("#kt_body").removeAttr("data-kt-aside-minimize");
		$("#kt_aside_toggle").removeClass("active");
	    }, 100);	
		}
		else
		{
	  	setTimeout(function() { 
		$("#kt_body").attr("data-kt-aside-minimize","on");
		$("#kt_aside_toggle").addClass("active");
		}, 100);
		}
   });
  }
 
  render() {

    if (LoggedIn()) {
    
      return (
				<div id="kt_aside" className="aside aside-dark aside-hoverable" data-kt-drawer="true" data-kt-drawer-name="aside" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_aside_mobile_toggle">
					<div className="aside-logo flex-column-auto" id="kt_aside_logo">
						<a href="/">
							<img alt="Logo" src="/alfalogo1.png" className="h-30px logo" />
						</a>
						<div id="kt_aside_toggle" className="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle" data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="aside-minimize">
							<span className="svg-icon svg-icon-1 rotate-180">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<path opacity="0.5" d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z" fill="black" />
									<path d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z" fill="black" />
								</svg>
							</span>
						</div>
					</div>
					<div className="aside-menu flex-column-fluid">
						<div className="hover-scroll-overlay-y my-5 my-lg-5" id="kt_aside_menu_wrapper" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer" data-kt-scroll-wrappers="#kt_aside_menu" data-kt-scroll-offset="0">
							<div className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500" id="#kt_aside_menu" data-kt-menu="true">
								<div className="menu-item">
									<div className="menu-content pb-2">
										<span className="menu-section text-muted text-uppercase fs-8 ls-1">GENEL</span>
									</div>
								</div>




								<div className="menu-item">
									<NavLink className="menu-link" to="/rehber">
										<span className="menu-icon">
										<i className="nav-icon fas fa-phone"></i>
										</span>
										<span className="menu-title">Rehber</span>
									</NavLink>
								</div>

								<div className="menu-item">
									<NavLink className="menu-link" to="/basvurular">
										<span className="menu-icon">
										<i className="nav-icon fas fa-file-alt"></i>
										</span>
										<span className="menu-title">Başvurular</span>
									</NavLink>
								</div>


								<div className="menu-item">
									<NavLink className="menu-link" to="/teklifler">
										<span className="menu-icon">
										<i className="nav-icon fas fa-file"></i>
										</span>
										<span className="menu-title">Teklifler</span>
									</NavLink>
								</div>

								<div className="menu-item">
									<NavLink className="menu-link" to="/projeler">
										<span className="menu-icon">
										<i className="nav-icon fas fa-file-contract"></i>
										</span>
										<span className="menu-title">Projeler</span>
									</NavLink>
								</div>



								<div className="menu-item">
									<NavLink className="menu-link" to="/hesabim">
										<span className="menu-icon">
										<i className="nav-icon fas fa-unlock"></i>
										</span>
										<span className="menu-title">Hesabım</span>
									</NavLink>
								</div>								
								
								{/* <div className="menu-item">
									<NavLink className="menu-link" to="/oturumu-kapat">
										<span className="menu-icon">
										<i className="nav-icon fas fa-power-off"></i>
										</span>
										<span className="menu-title">Oturumu Kapat</span>
									</NavLink>
								</div> */}

							</div>
						</div>
					</div>

				</div>

          );
        }
        return (
          <aside className="main-sidebar sidebar-dark-primary elevation-4">
        
        <div id="logo">
    <a href="#" className="brand-link">
      <img src='/alfalogo48.png'
           alt=""
           className="x"
           >
    </img>
      <span className="brand-text font-weight-light"><b>AlfaCERT</b></span>
    </a>
  </div>
 
          
          <div id="sidebar" className="sidebar">
         
            
            <nav className="mt-2">
              <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
               
                        
           
                     <li className="nav-item">
                      <NavLink id="idoturumac" to="/oturum-ac" className="nav-link">
                        <i className="nav-icon fas fa-unlock"></i>
                        <p>
                          Oturum Aç
                         
                        </p>
                      </NavLink>
                    </li>
        
                <li className="nav-item">
                  <NavLink id="idsifremiunuttum" to="/sifremi-unuttum" className="nav-link">
                    <i className="nav-icon fas fa-key"></i>
                    <p>
                     Şifremi Unuttum
        
                    </p>
                  </NavLink>
                </li>
        
              </ul>
            </nav>
          
          </div>
        
        </aside>
              );
            }
      }

export default Sidebar;
