import React, { useContext, useState, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import baseURL from "../services/baseURL";
import CacheItem from "../services/CacheItem";
//import jquery from 'jquery';
import RefreshToken from "../services/RefreshToken";
import { Dropbox } from "dropbox";
// import 'select2';
// import 'select2/dist/css/select2.css';
import Modal from "react-modal";
import TagsInput from "react-tagsinput";
import { Button, Popover, PopoverHeader, PopoverBody } from "reactstrap";
import "react-tagsinput/react-tagsinput.css";
// import 'bootstrap-filestyle/src/bootstrap-filestyle.js';
import { Redirect, NavLink } from "react-router-dom";
import "./RehberDosyalar.css";
import AuthContext from "../services/AuthContext";
import request from "../services/Request";
import $ from "jquery";
import ReactTooltip from "react-tooltip";
import TabloButonlari from "../components/TabloButonlari";
import SayfalamaButonlari from "../components/SayfalamaButonlari";
import axios from "axios";

const accessToken =
  "Xp5kGM5djCcAAAAAAAAAAaEX3GZRtd1wcfR-mVNdE7bigzIq7mQ2QvnqDwdN3msP";
const dbx = new Dropbox({ accessToken });

const btnstyle = {
  backgroundColor: "transparent",
  border: "none",
  padding: "0px",
  margin: "0px",
};
const downclass = "fa fa-angle-down";
const upclass = "fa fa-angle-up";

const customStyles = {
  content: {
    width: "500px",
    height: "300px",
    backgroundColor: "white",
    /* Center vertically and horizontally */
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: "-150px 0 0 -250px",
  },
  overlay: {
    backgroundColor: "rgba(114, 137, 165, 0.75)",
  },
};

function OrderComp(props) {
  if (props.col == props.sortingProperty) {
    return (
      <button
        className={props.sortedAscending ? downclass : upclass}
        style={btnstyle}
      ></button>
    );
  } else {
    return <div></div>;
  }
}

function firstLetter(str) {
  var parcalar = str.split(" ");
  for (var i = 0; i < parcalar.length; i++) {
    var j = parcalar[i].charAt(0).toLocaleUpperCase("TR");
    parcalar[i] = j + parcalar[i].substr(1).toLocaleLowerCase("TR");
  }
  return parcalar.join(" ");
}

function refresh_token() {
  axios
    .post(baseURL() + "/refreshtoken", {
      refresh: localStorage.refresh,
    })
    .then(
      (response) => {
        localStorage.token = response.data.access;
        localStorage.refresh = response.data.refresh;
        //alert(config.headers["Authorization"]);
        //alert(config.headers["Authorization"]);
      },
      (error) => {}
    );
}

function RehberDosyalar(props) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [RedirectURL, setRedirectURL] = useState("");
  const [filtre, setFiltre] = useState("SEÇİNİZ");
  const [elemanlar, setElemanlar] = useState([
    "SEÇİNİZ",
    "ADI / ÜNVANI",
    "REHBER NO",
    "TC KİMLİK / VERGİ NO",
    "ŞEHİR",
    "İLÇE",
    "MAHALLE / KÖY",
    "TELEFON",
    "TÜZEL KİŞİ",
  ]);
  const [data, setData] = useState(null);
  const [info, setInfo] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [path, setPath] = useState("/alfacert/rehber" + "/id_" + props.id);
  const [paths, setPaths] = useState([]);

  const [onizlemeModu, setOnizlemeModu] = useState(false);
  const [count, setCount] = useState(0);

  const maxSize = 209715200;

  const dragEnter = (ev) => {
    // ev.target.closest('table').style.border = "3px dotted red";
    // Prevent default behavior (Prevent file from being opened)
    // ev.preventDefault();
  };

  const dragEnd = (ev) => {
    alert("end");
    ev.target.closest("table").style.border = "3px dotted yellow";

    // Prevent default behavior (Prevent file from being opened)

    // ev.preventDefault();
  };

  const Drop = (ev, path) => {
    alert(path);
    alert(ev.dataTransfer.files[0].name);

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
  };

  const onDrop = useCallback((acceptedFiles) => {
    var formData;
    handleCloseModal();
    refresh_token();
    for (let i = 0; i < acceptedFiles.length; i++) {
      formData = new FormData();
      formData.append("file", acceptedFiles[i]);
      dbx.filesUpload(acceptedFiles[i]);
      // alert(acceptedFiles[i].name);

      //request('POST', '/postfile',formData,postBasarili, postBasarisiz,'');
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize,
  });

  const [HedefUrl, setHedefUrl] = useState("YOK");

  const context = useContext(AuthContext);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const hasClass = (ele, cls) => {
    return ele.className.match(new RegExp("(\\s|^)" + cls + "(\\s|$)"));
  };

  const OnizlemeHandle = () => {
    setOnizlemeModu(!onizlemeModu);
  };

  const DownloadXls = () => {
    $.ajax({
      type: "GET",
      url: baseURL + "/exportxlsx",
      headers: {
        Authorization: "Bearer " + localStorage.token,
      },
      xhrFields: { responseType: "blob" },
      success: function (data) {
        var blob = new Blob([data]);
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "text.xlsx";
        link.click();
      },
      //
      error: function (jqXHR, textStatus, errorThrown) {
        if (jqXHR.status == 401) {
          RefreshToken(this);
        }
      },
      //
    });
  };

  const download_Basarili = (response) => {
    //alert(JSON.stringify(response.data));
    //alert(response.data);
    var blob = new Blob([response.data]);
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "filename.pdf";
    link.click();
    //alert(response.data.unvaniadi);
  };

  const download_Basarisiz = (response) => {};

  const DownloadFromDropbox = (dropboxfilename) => {
    var destfilename = dropboxfilename.replace(/^.*[\\\/]/, "");
    $.ajax({
      type: "GET",
      data: { filename: dropboxfilename },
      url: baseURL() + "/downloadfromdropbox",
      headers: {
        Authorization: "Bearer " + localStorage.token,
      },
      xhrFields: { responseType: "blob" },
      success: function (data) {
        var blob = new Blob([data]);
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = destfilename;
        link.click();
        setLoading(false);
      },
      //
      error: function (jqXHR, textStatus, errorThrown) {
        if (jqXHR.status == 401) {
          RefreshToken(this);
          setLoading(false);
        }
      },
      //
    }); //request('GET', '/downloadfromdropbox', {'filename':filename}, download_Basarili, download_Basarisiz, '');
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const getInfoFromId_Basarili = (response) => {
    //alert(JSON.stringify(response.data));

    //alert(path);
    dbx
      .filesListFolder({
        path: path,
      })
      .then((response) => {
        var files = response.result.entries;

        setData(files);
        // setLoading(false);
        //dbx.filesGetThumbnail({ path: path, format: 'png', size: 'w128h128' })
        for (var i = 0; i < files.length; i++) {
          //   var tag = files[i]['.tag'];
          //   dbx.filesGetThumbnail({ path: files[i].path_lower, format: 'png', size: 'w128h128' })
          //     .then(function (response) {
          //       //alert(JSON.stringify(response.result));
          //       var img = document.createElement('img');
          //       var image = window.URL.createObjectURL(response.result.fileBlob);
          //       if (image != null) img.src = image;
          //       //thumnails.push(img.src);
          //       //alert(img.src);
          //       //document.getElementById('results').appendChild(img);
          setThumnail(files[i].path_lower, i);
          if (i == files.length - 1) setLoading(false);
        }
        //)
        // }

        //alert("dkd");
      });
    //alert(response.data.unvaniadi);
    setInfo(response.data);
  };

  const getInfoFromId_Basarisiz = (response) => {
    setLoading(false);
  };

  useEffect(() => {
    ReactTooltip.rebuild();

    //fetchData(currentPage, recordsPerPage, sortingProperty, sortingColumnIndex, sortedAscending);
    setLoading(true);
    //request('GET', '/getlistfilesinfolder', {'id':props.id,'path':path}, BilgilerimiAl_Basarili, BilgilerimiAl_Basarisiz, '');

    request(
      "GET",
      "/getinfofromid",
      { id: props.id },
      getInfoFromId_Basarili,
      getInfoFromId_Basarisiz,
      ""
    );

    return () => {
      //unmount
    };
  }, [path, onizlemeModu]);

  const setThumnail = (path, key) => {
    dbx
      .filesGetThumbnail({ path, format: "png", size: "w128h128" })
      .then(function (response) {
        //alert(JSON.stringify(response.result));
        var img = document.getElementById(key);
        var image = window.URL.createObjectURL(response.result.fileBlob);
        if (img) if (image) img.src = image; //alert(img.src);
        //document.getElementById('results').appendChild(img);
      });
    //setLoading(true);
    return null;
  };

  const UnvanKisalt = (value) => {
    var a = value.length > 20 ? value.substr(0, 20) + "…" : value;
    return a;
  };

  const doubleClick = (event, key) => {
    //alert(event.currentTarget.rowIndex);
    context.setLoader(true);
      if (data[key][".tag"] == "folder") {
      var pth = data[key].path_lower.replace(path, "");
      //alert(pth);
      setPath(path + pth);
      var fltr = (path + pth).replace("/alfacert/rehber/id_" + props.id, "");
      fltr = fltr.split("/");
      fltr.shift();
      //alert(fltr);
      setPaths(fltr);
      // for (var i = 0; i < fltr.length; i++) {
      // }
    } else {
      //alert("download");
      // alert("indir->: "+data[key].path_lower);
      // setLoading(true);

      // dbx.filesGetThumbnail({ path: data[key].path_lower, format: 'png', size: 'w128h128' })
      //   .then(function (response) {
      //     //alert(JSON.stringify(response.result));
      //     var img = document.createElement('img');
      //     img.src = window.URL.createObjectURL(response.result.fileBlob);
      //     //alert(img.src);
      //     // document.getElementById('results').appendChild(img);

      //   })

      var hata = false;

      dbx.filesDownload({ path: data[key].path_lower }).then(async function (response) {
          if (window.showSaveFilePicker) {
            var filename = data[key].path_lower.replace(path, "");
            filename = filename.split("/");
            filename.shift();
            const handle = await window.showSaveFilePicker({ suggestedName: filename })
            .catch((err) => (hata = true));
            if (!hata) {
              const writable = await handle.createWritable();
              var image = response.result.fileBlob;
              await writable.write(image);
              writable.close();
            }
            else
            {
              context.setLoader(false);
            }
          } else {
            var blob = response.result.fileBlob;
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = response.result.name;
            link.click();
            setLoading(false);
            context.setLoader(false);
          }
        })
        .catch(function (error) {
          context.setLoader(false);
          //alert(error);
        });
      //DownloadFromDropbox(data[key].path_lower);
    }
  };

  const Template_Table2 = () => {
    return (
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th className="no">
                DOSYA ADI<OrderComp col={"isim"}></OrderComp>
              </th>
              <th className="dosyaturu">
                BOYUT<OrderComp col={"degisiklikzamani"}></OrderComp>
              </th>
              <th className="dosyaturu">
                DOSYA TÜRÜ<OrderComp col={"degisiklikzamani"}></OrderComp>
              </th>
              <th className="adiunvani">
                DEĞİŞİKLİK ZAMANI
                <OrderComp col={"degisiklikzamani"}></OrderComp>
              </th>
            </tr>
          </thead>
          {data != null
            ? data.map((val, key) => {
                return (
                  <tr
                    onDoubleClick={(event) => doubleClick(event, key)}
                    key={key}
                    onDrop={(event) => Drop(event, val.path_lower)}
                    onDragEnter={(event) => dragEnter(event)}
                  >
                    <td data-column="İSİM">
                      {val[".tag"] == "folder" ? (
                        <img src="/static/dist/img/folder.png" />
                      ) : val.name.split(".").pop() == "pdf" ? (
                        <img src="/static/dist/img/pdf.png" />
                      ) : val.name.split(".").pop() == "xls" ? (
                        <img src="/static/dist/img/xlsx.png" />
                      ) : val.name.split(".").pop() == "xlsx" ? (
                        <img src="/static/dist/img/xlsx.png" />
                      ) : val.name.split(".").pop() == "docx" ? (
                        <img src="/static/dist/img/docx.png" />
                      ) : val.name.split(".").pop() == "doc" ? (
                        <img src="/static/dist/img/docx.png" />
                      ) : val.name.split(".").pop() == "jpg" ? (
                        <img src="/static/dist/img/jpg.png" />
                      ) : val.name.split(".").pop() == "JPG" ? (
                        <img src="/static/dist/img/jpg.png" />
                      ) : val.name.split(".").pop() == "ico" ? (
                        <img src="/static/dist/img/jpg.png" />
                      ) : (
                        <img src="/static/dist/img/misc.png" />
                      )}
                      &nbsp;&nbsp;&nbsp;&nbsp;<label onClick={(event) => doubleClick(event, key)}>{val.name}</label>
                    </td>
                    <td
                      className="me-2 fs-7 fw-bold"
                      data-column="DOSYA BOYUTU"
                    >
                      {val.size ? Math.round(val.size / 1024) + " KB" : "--"}
                    </td>
                    <td className="me-2 fs-7 fw-bold" data-column="DOSYA TÜRÜ">
                      {val[".tag"] != "folder"
                        ? val.name.split(".").pop().toLocaleUpperCase() +
                          " Dosya"
                        : "Klasör"}
                    </td>
                    <td
                      className="me-2 fs-7 fw-bold"
                      data-column="DEĞİŞİKLİK ZAMANI"
                    >
                      {val.client_modified
                        ? new Date(val.client_modified).toLocaleDateString(
                            "tr-TR"
                          ) +
                          " / " +
                          new Date(val.client_modified).toLocaleTimeString(
                            "tr-TR"
                          )
                        : "--"}
                    </td>
                  </tr>
                );
              })
            : null}
        </table>
      </div>
    );
  };

  const Template_Table = () => {
    return (
      <div className="table-responsive">
        <table id="mytab">
          <thead>
            <tr>
              <th className="dosyaturu">
                ÖNİZLEME<OrderComp col={"degisiklikzamani"}></OrderComp>
              </th>
              <th className="no">
                DOSYA ADI<OrderComp col={"isim"}></OrderComp>
              </th>
              <th className="dosyaturu">
                BOYUT<OrderComp col={"degisiklikzamani"}></OrderComp>
              </th>
              <th className="dosyaturu">
                DOSYA TÜRÜ<OrderComp col={"degisiklikzamani"}></OrderComp>
              </th>
              <th className="adiunvani">
                DEĞİŞİKLİK ZAMANI
                <OrderComp col={"degisiklikzamani"}></OrderComp>
              </th>
            </tr>
          </thead>
          {data != null
            ? data.map((val, key) => {
                return (
                  <tr
                    onDoubleClick={(event) => doubleClick(event, key)}
                    key={key}
                    onDrop={(event) => Drop(event, val.path_lower)}
                    onDragEnter={(event) => dragEnter(event)}
                  >
                    <td data-column="ÖNİZLEME">
                      {val[".tag"] == "folder" ? (
                        <img src="/static/dist/img/folder100x100.png" />
                      ) : (
                        <img id={key} />
                      )}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </td>

                    <td data-column="DOSYA ADI">{val.name} </td>
                    <td
                      className="me-2 fs-7 fw-bold"
                      data-column="DOSYA BOYUTU"
                    >
                      {val.size ? Math.round(val.size / 1024) + " KB" : "--"}
                    </td>
                    <td className="me-2 fs-7 fw-bold" data-column="DOSYA TÜRÜ">
                      {val[".tag"] != "folder"
                        ? val.name.split(".").pop().toLocaleUpperCase() +
                          " Dosya"
                        : "Klasör"}
                    </td>
                    <td
                      className="me-2 fs-7 fw-bold"
                      data-column="DEĞİŞİKLİK ZAMANI"
                    >
                      {val.client_modified
                        ? new Date(val.client_modified).toLocaleDateString(
                            "tr-TR"
                          ) +
                          " / " +
                          new Date(val.client_modified).toLocaleTimeString(
                            "tr-TR"
                          )
                        : "--"}
                    </td>
                  </tr>
                );
              })
            : null}
        </table>
      </div>
    );
  };

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const clickName = (name) => {
    if (name == "home") {
      setPath("/alfacert/rehber" + "/id_" + props.id);
      setPaths([]);
    } else {
      var fltr = path.substring(0, path.indexOf(name)) + name;
      setPath(fltr);
      fltr = fltr.replace("/alfacert/rehber/id_" + props.id, "");
      fltr = fltr.split("/");
      fltr.shift();
      // alert(fltr);
      setPaths(fltr);
    }
  };

  const Template = () => {
    return (
      <div className="RehberDosyalar">
        <div className="App"></div>

        <div id="carddiv" style={{ display: "block" }}>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-12">
                  <ol className="breadcrumb float-sm-left">
                    <li className="breadcrumb-item">
                      <NavLink to="/rehber">Rehber</NavLink>
                    </li>
                    <li className="breadcrumb-item active">Dosyalar</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card card-outline cardm">
                    <div className="card-header">
                      <div
                        className="tagWrapperDiv"
                        style={{
                          display:
                            context.RehberFilter.length > 0 ? "block" : "none",
                        }}
                      ></div>

                      <div id="buttondiv">
                        <div
                          className="btn-group"
                          style={{ marginBottom: "5px" }}
                        >
                          <button
                            id="PopoverFiltre"
                            className="btn btn-sm btn-link gbtn"
                            data-tip="Filtre Ekle"
                          >
                            <i className="la la-filter"></i>
                          </button>{" "}
                          <button
                            className="btn btn-sm btn-link gbtn"
                            data-tip="Karşıya Yükle"
                            onClick={handleOpenModal}
                          >
                            <i className="la la-upload"></i>
                          </button>
                          <button
                            className="btn btn-sm btn-link gbtn"
                            data-tip={
                              onizlemeModu
                                ? "Önizleme Modunu Kapat"
                                : "Önizleme Modunu Aç"
                            }
                            onClick={OnizlemeHandle}
                          >
                            <i
                              className={
                                onizlemeModu
                                  ? "fa fa-thin fa-list"
                                  : "la la-image"
                              }
                            ></i>
                          </button>
                        </div>
                      </div>
                      <Modal
                        isOpen={showModal}
                        onRequestClose={() => handleCloseModal()}
                        style={customStyles}
                        contentLabel=""
                      >
                        <div
                          {...getRootProps()}
                          style={{
                            width: "100%",
                            height: "100%",
                            backgroundColor: "transparent",
                            textAlign: "center",
                          }}
                        >
                          <div className="box has-advanced-upload">
                            <div class="box__input">
                              <svg
                                class="box__icon"
                                xmlns="http://www.w3.org/2000/svg"
                                width="50"
                                height="43"
                                viewBox="0 0 50 43"
                              >
                                <path d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z"></path>
                              </svg>
                              <input {...getInputProps()} />

                              {isDragActive ? (
                                <p>Dosyaları buraya bırakın...</p>
                              ) : (
                                <p>
                                  Dosyalarınızı buraya sürükleyin <br /> veya
                                  tıklayıp dosyaları seçin...
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </Modal>

                      <div id="path">
                        {info ? (
                          <label onClick={() => clickName("home")}>
                            <a id="nme">
                              {info.musno
                                ? info.musno + " - " + info.unvaniadi
                                : info.unvaniadi}
                              &nbsp;&nbsp;
                            </a>
                          </label>
                        ) : null}

                        {paths.map((name) => (
                          <label
                            onClick={() =>
                              paths[paths.length - 1] != name
                                ? clickName(name)
                                : null
                            }
                          >
                            /&nbsp;&nbsp;
                            <a
                              id={paths[paths.length - 1] != name ? "nme" : ""}
                            >
                              {name}
                            </a>
                            &nbsp;&nbsp;
                          </label>
                        ))}
                      </div>
                      {/* <div id="info">
     
               </div> */}

                      <div id="sildiv" className=".modal">
                        <div className="sdv">
                          <div className="alert alert-secondary">
                            <button
                              onClick="FirmaSil();"
                              className="btn btn-xs btn-danger pull-right sbtn"
                            >
                              Sil
                            </button>
                            <button
                              onClick="ModalKapat();$('#sildiv').hide();"
                              className="btn btn-xs btn-secondary pull-right"
                            >
                              Vazgeç
                            </button>
                            <strong>Uyarı: </strong> <a id="sillabel"></a>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* {this.state.FiltreControl ? <Rehber_Filtre func={this.ShowHideRehberFiltre} funcRehberFiltreEkle={this.RehberFiltreEkle} /> : null} */}

                    {onizlemeModu ? Template_Table() : Template_Table2()}

                    <div id="results"></div>

                    <div className="cardfooter">
                      {/* <div className="float-right">{numberWithCommas(recordsTotal)} kayıttan  {(currentPage - 1) * recordsPerPage == 0 ? 1 : numberWithCommas((currentPage - 1) * recordsPerPage + 1)} ile {(currentPage - 1) * recordsPerPage + recordsPerPage > recordsTotal ? numberWithCommas(recordsTotal) : numberWithCommas((currentPage - 1) * recordsPerPage + recordsPerPage)} arası kayıtlar gösteriliyor.</div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <ReactTooltip
          place="bottom"
          type="dark"
          className="customtooltip"
          textColor="white"
          backgroundColor="#44567c"
          effect="solid"
        />
      </div>
    );
  };

  if (RedirectURL) return <Redirect push to={RedirectURL} />;

  if (error) {
    context.setLoader(false);
    return <div>Bir hata oluştu.</div>;
  }

  // if (loading) {

  //   if (!context.HTML) {
  //     return <div className="loaderdiv"></div>;
  //   }
  //   else {
  //     context.setLoader(true);
  //     return context.HTML;
  //   }
  // }

  context.HTML = null;
  context.setLoader(false);
  return Template();
}

export default RehberDosyalar;
