import React from 'react';

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

function SayfalamaButonlari(props) {

  return (
    <div style={{ width: '100%', height: '28px',marginBottom:'4px' }}>
      <div id="buttondivr">

        <div className="" style={{ marginTop: '5px' }}>

          <a onClick={props.IlkSayfa} data-tip="İlk Sayfa" className="btn btn-icon btn-bg-light btn-color-primary btn-sm me-1">
            <i className="fas fa-fast-backward"></i>
          </a>

          <a onClick={props.OncekiSayfa} data-tip="Önceki Sayfa" className="btn btn-icon btn-bg-light btn-color-primary btn-sm me-1">
            <i className="fas fa-backward"></i>
          </a>

          <label className="btn btn-bg-light btn-sm me-1">Sayfa {numberWithCommas(props.currentPage)} / {numberWithCommas(Math.ceil(props.recordsTotal / props.recordsPerPage))}</label>

          <a onClick={props.SonrakiSayfa} data-tip="Sonraki Sayfa" className="btn btn-icon btn-bg-light btn-color-primary btn-sm me-1">
            <i className="fas fa-forward"></i>
          </a>

          <a onClick={props.SonSayfa} data-tip="Son Sayfa" className="btn btn-icon btn-bg-light btn-color-primary btn-sm me-1">
            <i className="fas fa-fast-forward"></i>
          </a></div>
      </div>
    </div>
  )

}

export default SayfalamaButonlari;