import React,{ useContext, useState, useEffect } from 'react';
import './Projeler.css';
import AuthContext from '../services/AuthContext';

// datayı state e al render anında map et
//      this.setState({ loading: false });

function Projeler() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  
  const context = useContext(AuthContext);

  useEffect(() => {
    setLoading(false);
}, []);

    // if (!navigator.onLine) 
    // return <div>Hata:İnternet bağlantısı yok...</div>;

    if (error) {
      setLoading(false);
      return <div>Bir hata oluştu.</div>;
  }

  return (
    <div>
        <div id="carddiv">
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-12">
                            <ol className="breadcrumb float-sm-left">
                                <li className="breadcrumb-item"><a href="#"></a>Projeler</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
);

}

export default Projeler;