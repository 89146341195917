import baseURL from '../services/baseURL';
import $ from 'jquery';
import './RehberEkle.css'; 
import React, { useContext, useState, useEffect, useRef } from 'react';
import AuthContext from '../services/AuthContext';
import { showSuccessmessage, showErrormessage } from '../services/Messages.js';
import api from '../services/Api';
import { useQuery, useMutation, QueryClient, QueryClientProvider } from "react-query";
import Select2 from 'react-select2-wrapper';
import Context from '../context/store'


//import '../components/scripts.bundle.js';  
// import '@yaireo/tagify';

const options = {
	placeholder: "Seçiniz",
	language: {
		noResults: function () {
			return "Sonuç bulunamadı...";
		} 
	},
};

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
	},
};
// datayı state e al render anında map et
//      this.setState({ loading: false });

function RehberEkle() {
	const {state, dispatch} = useContext(Context);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [tckimlikvergino, setTckimlikvergino] = useState("");
	const [unvaniadi, setUnvaniadi] = useState("");
	const [hata, setHata] = useState(false);
	const [tuzelkisilik, setTuzelkisilik] = useState(false);
	const [tcvergino, setTcvergino] = useState('');
	const [elemanlar, setElemanlar] = useState(['SEÇİNİZ','USA dollar', 'British pound', 'Australian dollar', 'Japanese yen', 'Swedish krona', 'Canadian dollar', 'Swiss franc']);
	const [selectedValue, setSelectedValue] = useState('');
	const [selectedSehirValue, setSelectedSehirValue] = useState(null);
	const [selectedIlceValue, setSelectedIlceValue] = useState(null);
	const [ilceler, setIlceler] = useState([]);
	
	var contentStyle={};
	var py5Style={};
		
    const [style, setStyle] = useState({
        contentStyle: {
            marginTop: "0px",
        },

		containerxxlStyle:  {
            marginBottom: "20px",
        },

        py5Style: {
            paddingTop: "10px",
            paddingBottom: "0px",
        }
    });

	const context = useContext(AuthContext);
    
	const postRehberData = () => {

		var sehirid = ($("#mm1")[0].selectedIndex).toString();
	
		var ilceadi = $("#nn1 option:selected").text();
		//alert(unvaniadi);
	
		return api.post(`/setrehberdetay`, { 'id': '-1', 'tckimlikvergino': tcvergino, 'tuzelkisilik': tuzelkisilik, 'unvaniadi': unvaniadi, 'sehirid': sehirid, 'ilceadi': ilceadi });
	  };
	
	const fetchSehirlerData = async () => {

		const  result  = await api.get('/sehirler');
	
		return result.data; 
	  };
	
	  const sehirquery = useQuery('rehberSehirlerQueryClient', fetchSehirlerData, {
	
		onError: (error) => {
	
		  //alert(error.message);
		  setHata(true);
		  if (hata) {
			return (
	
			  <div id="loadericondiv2" style={{ display: 'block', color: 'lightgray', fontSize: '15px', marginTop: '0px' }} className="float-left">
				<div>Bir hata oluştu. {error.response.status}</div>
			  </div>
			);
		  }
	
		  if (error) if (error.response.status == "402") {
			return (
	
			  <div id="loadericondiv2" style={{ display: 'block', color: 'lightgray', fontSize: '15px', marginTop: '0px' }} className="float-left">
				<div>Bu bölümü görüntüleme yetkiniz yok. {error.response.status}</div>
			  </div>
	
			)
		  };
		  //console.log(error.message) 
	
	
		  setError(error);
	
		},
	  });
	
	
	  const fetchIlcelerData = async () => {

		const result  = await api.get('/ilceler' + '?sehirid=' + selectedSehirValue);
	//alert(JSON.stringify(result.data));
		return result.data; 
	  };
	

	  const { mutate } = useMutation(postRehberData, {
		onSuccess: () => {
		  state.queryClient.invalidateQueries("rehberQueryClient");
		  // if (isOnlyWhitespace(data.adi)) MediaStreamAudioDestinationNode() 
	
		  document.getElementById("kt_button_1").removeAttribute("data-kt-indicator");
		  //context.hideButtonloader('mbutton2');
		  showSuccessmessage('Bilgiler Kaydedildi...');
		},
		onError: () => {
		  document.getElementById("kt_button_1").removeAttribute("data-kt-indicator");
		  showErrormessage('İşlem başarısız oldu');
		}
	  });


	const handleSelectChange = (e) => {
        setSelectedValue(e.target.value);
       // alert(e.target.value);
    };

	const handleSehirSelectChange = async(e) => {
		//setSelectedIlceValue(null);
        setSelectedSehirValue(e.target.value);

		const result = await api.get('/ilceler' + '?sehirid=' + e.target.value);
		setIlceler(result.data);
      // alert(JSON.stringify(ilceler));
    };

	const handleIlceSelectChange = (e) => {
        setSelectedIlceValue(e.target.value);
       // alert(e.target.value);
    };

	const filtre_options = {
		placeholder: "Seçiniz",
		language: {
		  noResults: function () {
			return "Sonuç bulunamadı...";
		  }
		},
	  };
	  
	  const filtreSelected = () => {

	  }
	  
	  const Acildi = (e) => {
		document.querySelector('.select2-search__field').focus();
	  }

	  const Kapandi = (e) => {
		if (e.target.value)
		  setUnvaniadi(e.target.value); else setUnvaniadi(e.target.value);
	  }
	  const kisilikSelected = () => {
  
		if ($('#zz1').val() == "GERÇEK KİŞİ") {
		  setTuzelkisilik(false);
		  setTcvergino('');
		 }
		  else 
		  {
			setTuzelkisilik(true);
			setTcvergino('');
		  }
	
		$("#id_tcvergino:text").val("");
		if ($('#zz1').val() == "GERÇEK KİŞİ") {
	
		  // $("label[for*='tcverginolabel']").html("TC Kimlik No");
		  //$("#id_tcvergino").attr("placeholder", "Şahıs vergi mükellefi ise doldurulmalıdır !");
	
		  //   $("label[for*='ilgilikisilabel']").html("İrtibat Kurulacak Kişi");
		  $("#id_tcvergino").attr('maxlength', '11');
		  $("#id_tcvergino").attr('minlength', '11');
	
		  //$("#id_tcvergino").rules("remove", "minlength");
		  // $("#id_tcvergino").rules("add", { minlength: 11 });
		  // $("label[for*='firmalabel']").html("Üretici Adı Soyadı");
		  //  $("#vdairefield").rules("remove", "required");
		  //  $("#yetkilifield").rules("remove", "required");                
		}
	
		if ($('#zz1').val() == "TÜZEL KİŞİ") {
	
		  // $("label[for*='tcverginolabel']").html("Vergi No");
		  //$("#id_tcvergino").attr("placeholder", "");
		  //  $("label[for*='vdairelabel']").html("İrtibat Kurulacak Kişi");
		  //  $("label[for*='ilgilikisilabel']").html("Vergi Dairesi");
		  $("#id_tcvergino").attr('maxlength', '10');
		  $("#id_tcvergino").attr('minlength', '10');
		  // $("#id_tcvergino").val("");
		  // $("#id_tcvergino").rules("remove", "minlength");
		  //$("#id_tcvergino").rules("add", { minlength: 10 });
		  // $("label[for*='firmalabel']").html("Firma Ünvanı");               
		  // $("#vdairefield").rules("add", "required");
		  //  $("#yetkilifield").rules("add", "required");
		}
	  }

	  const degerSelected = (e) => {

		var unvan = e.target.value;
		var start = unvan.indexOf("(")
		var end = unvan.indexOf(")")
	
		setTckimlikvergino(unvan.substring(start + 1, end));
	
		setUnvaniadi(e.target.value);
	  }


	        const replaceValidationUI = (form) => {
            // Suppress the default bubbles
            form.addEventListener("invalid", function (event) {
                event.preventDefault();
            }, true);
    
            // Support Safari, iOS Safari, and the Android browser—each of which do not prevent
            // form submissions by default
            form.addEventListener("submit", function (event) {
                if (!this.checkValidity()) {
                    event.preventDefault();
                }
            });
    
            var submitButton = form.querySelector("button:not([type=button]), input[type=submit]");
    
            if (submitButton) {
            submitButton.addEventListener("click", function (event) {
                var invalidFields = form.querySelectorAll(":invalid"),
                    errorMessages = form.querySelectorAll(".error-message"),
                    parent;
    
                // Remove any existing messages
                for (var i = 0; i < errorMessages.length; i++) {
                    errorMessages[i].parentNode.removeChild(errorMessages[i]);
                }
    
                for (var i = 0; i < invalidFields.length; i++) {
                    parent = invalidFields[i].parentNode;
                    if (invalidFields[i].validationMessage.search("eşleştirin") > -1) {
    
                        parent.insertAdjacentHTML("beforeend", "<div class='error-message'>" +
                            "En az bir rakam, bir büyük ve küçük harf ve en az 8 veya daha fazla karakter içermelidir." +
                            "</div>");
                    }
                    else {
                        parent.insertAdjacentHTML("beforeend", "<div class='error-message'>" +
                            invalidFields[i].validationMessage +
                            "</div>");
                    }
                }
    
                // If there are errors, give focus to the first invalid field
                if (invalidFields.length > 0) {
                    invalidFields[0].focus();
                }
            });
        }
        }  

		const BilgileriKaydet = (e) => {
			// Replace the validation UI for all forms

			var form = document.getElementById('RehberEkleForm');
	
			var isValid = form.checkValidity();
			if (isValid) {
	
				//setLoading(true);
				document.getElementById("kt_button_1").setAttribute("data-kt-indicator", "on");
				
				//mutate();
			} 
			
			else 
			
			{
				
			}

		}


		useEffect(() => {
			
			   var form = document.getElementById('RehberEkleForm');
			   if (form) replaceValidationUI(form);
	   
		},[]) // Bir Kere 

	useEffect(() => {

		dispatch({type:"SET_CURRENTPAGENAME",payload:"Rehber"})

		dispatch({type:"SET_CURRENTSUBPAGENAME",payload:"Ekle"})
	
		dispatch({type:"SET_CURRENTRECCOUNT",payload:-1})
		
		if (window.innerWidth < 990) {

			setStyle({
				contentStyle:{
				 marginTop: "0px",
			   },

			   containerxxlStyle:  {
				marginBottom: "100px",
			},
				  
			   py5Style:{
				paddingTop: "0px",
				paddingBottom: "0px",
			   },
		})
		 
		   }
		   else
		   {
		   
		   setStyle({
			contentStyle:{
			 marginTop: "0px",
		   },

		   containerxxlStyle:  {
            marginBottom: "100px",
        },
		  
		   py5Style:{
			paddingTop: "10px",
		  	paddingBottom: "0px",
	       },
		   })

		   }
		
		return () => {
			
		}
	}, [])

    const formPreventDefault = (e) => {
        e.preventDefault();
    }



const Template = () => {
return (
	
<div className="RehberEkle" style={{ marginTop:"-20px",marginBottom:"130px" }}>

<div className="content d-flex flex-column flex-column-fluid" style={style.contentStyle} id="kt_content">


<div id="kt_content_container" className="container-xxl" style={style.containerxxlStyle}>

<form id="RehberEkleForm" method="post" autocomplete="off" onSubmit={formPreventDefault}>

<div className="row gy-0 mb-6 mb-xl-12">
	
	<div className="col-md-12">

	
	</div>
	</div>


  <div id="info" className="card  card-md-stretch me-xl-3 mb-md-0 mb-6">
  
  <div className="card-header">

<div className="card-title">
<h4 className="fw-bolder">Genel Bilgiler</h4>
</div>

<div className="card-toolbar">

	<button id="kt_button_1" onClick={(e) => BilgileriKaydet(e.target)} className="btn btn-primary btn-sm">
		<span className="indicator-label">
			Kaydet
		</span>
		<span className="indicator-progress">
			Kaydet<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
		</span>
	</button>
</div>

</div>
  
  
<div className="row gy-0 mb-6 mb-xl-12">
		<div className="col-md-6">

	
{/* -----------------------------------------  */}
<div className="card-body">

<div className="d-flex flex-wrap" style={style.py5Style}>

	<div className="form-group mdv" style={{ width: "100%" }}>
		<label for="adilabel" className="required fs-6 fw-bold mb-2 control-label">Adı Soyadı / Ünvanı</label>
		
		<label className="error" for="adifield"></label>
		<input id="id_ad" name="ad" className="form-control form-control-solid" spellcheck="false" type="text" required="required" maxlength="100"
			defaultValue={""} onChange={"e => data.adi = e.target.value"} placeholder="" />
	</div>

	<div className="form-group mdv" style={{ width: "100%" }}>
		<label for="soyadilabel" className="required fs-6 fw-bold mb-2 control-label">Adres</label>
		<label className="error" for="soyadifield"></label>
		<input id="id_soyad" name="soyad" className="form-control form-control-solid" spellcheck="false" type="text" required="required"
			maxlength="100" defaultValue={""} onChange={"e => data.soyadi = e.target.value"} placeholder="" />
	</div>

</div>
</div>


{/* -----------------------------------------  */}


	 
	</div>



	<div className="col-md-6">



{/* -----------------------------------------  */}
<div className="card-body">

<div className="d-flex flex-wrap" style={style.py5Style}>

<div className="form-group mdv" style={{ width: "100%" }}>
		<label for="adilabel" className="required fs-6 fw-bold mb-2 control-label">Şehir</label>
		<Select2 id="sid3"
                className="form-control form-control-solid"
                style={{ width: "100%", height:"500px" }}
                multiple={false}
                data={sehirquery.data}
                //defaultValue={1}
                value={selectedSehirValue}
                options={{
					//tags: true, // Olmayan seçeneği ekleme
					placeholder: 'Seçiniz',
					//allowClear: true, // Clear seçeneği ekler
                    // placeholder: 'Seçiniz',
				//minimumResultsForSearch: -1, 
				language: {
					noResults: ''
                    
                }}}
				onChange={handleSehirSelectChange}
				required
              />
	</div>

	<div className="form-group mdv" style={{ width: "100%" }}>
		<label for="adilabel" className="required fs-6 fw-bold mb-2 control-label">İlçe</label>
		<Select2 id="sid4"
                className="form-control form-control-solid"
                style={{ width: "100%", height:"500px" }}
                multiple={false}
                data={ilceler}
                //defaultValue={1}
                value={selectedIlceValue}
                options={{
					//tags: true, // Olmayan seçeneği ekleme
					placeholder: 'Seçiniz',
					//allowClear: true, // Clear seçeneği ekler
                    // placeholder: 'Seçiniz',
				//minimumResultsForSearch: -1, 
				language: {
					noResults: ''
                    
                }}}
				onChange={handleIlceSelectChange}
				required
              />
	</div>

</div>
</div>


{/* -----------------------------------------  */}



</div>
  </div>

  </div>

  <div id="info" className="card  card-md-stretch me-xl-3 mb-md-0 mb-6" style={{ marginTop:"20px"}}>
  
  <div className="card-header">

<div className="card-title">
<h4 className="fw-bolder">Vergi Bilgileri</h4>
</div>

</div>


<div className="row gy-0 mb-6 mb-xl-12">
		<div className="col-md-6">

	
{/* -----------------------------------------  */}
<div className="card-body">

<div className="d-flex flex-wrap" style={style.py5Style}>

	<div className="form-group mdv" style={{ width: "100%" }}>
		<label for="adilabel" className="required fs-6 fw-bold mb-2 control-label">Statü</label>
		<Select2 id="sid"
                className="form-control form-control-solid"
                style={{ width: "100%", height:"500px" }}
                multiple={false}
                data={[
                  { text: 'Gerçek Kişi', id: 1 },
                  { text: 'Tüzel Kişi', id: 2 },
                ]}
                //defaultValue={1}
                value={selectedValue}
                options={{
					//tags: true, // Olmayan seçeneği ekleme
					placeholder: 'Seçiniz',
					//allowClear: true, // Clear seçeneği ekler
                    // placeholder: 'Seçiniz',
				//minimumResultsForSearch: -1, 
				language: {
					noResults: ''
                    
                }}}
				onChange={handleSelectChange}
				required
              />
	</div>



	<div class="d-flex flex-stack">
                          
                         
                        </div>
</div>
</div>


{/* -----------------------------------------  */}


	 
	</div>



	<div className="col-md-6">



{/* -----------------------------------------  */}
<div className="card-body" >

<div className="d-flex flex-wrap" style={style.py5Style}>

	<div className="form-group mdv" style={{ width: selectedValue == 2 ? '100%' : '100%' , marginTop:"10px"}}>
		<label for="adilabel" className="required fs-6 fw-bold mb-2 control-label">{selectedValue==2 ?  "Vergi No":"TC Kimlik No" }</label>
		<label className="error" for="adifield"></label>
		<input id="id_ad" name="ad" className="form-control form-control-solid" type="text" spellcheck="false" required="required" maxlength="100"
			defaultValue={""} onChange={"e => data.adi = e.target.value"} placeholder="" />
	</div>

</div>

</div>



{/* -----------------------------------------  */}



</div>

<div className="col-md-12">


<div className="d-flex flex-wrap" style={style.py5Style}>

{selectedValue==2 ? 
	<div className="form-group mdv" style={{ width: "100%" }}>
		<label for="soyadilabel" className="required fs-6 fw-bold mb-2 control-label"> &nbsp;Vergi Dairesi</label>
		<label className="error" for="soyadifield"></label>
		<input id="id_soyad" name="soyad" className="form-control form-control-solid" spellcheck="false" type="text" required="required"
			maxlength="100" defaultValue={""} onChange={"e => data.soyadi = e.target.value"} placeholder="" />
	</div>: null
	}

</div>
</div>
  </div>


</div>




</form>

</div>



      




				</div>

			
			</div>
		);

	}

	// if (!navigator.onLine) 
	// return <div>Hata:İnternet bağlantısı yok...</div>;

	if (error) {
		//context.setLoader(false);
		return <div>Bir hata oluştu.</div>;
	}

	if (loading) {

		if (context.HTML == null) {
			return <div className="loaderdiv"></div>;
		}
		else {
			//context.setLoader(true);
			return context.HTML;
		}
	}

	context.HTML = Template();
	//context.setLoader(false);
	return context.HTML;


}


export default RehberEkle;