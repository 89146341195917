import React, { useContext, useState, useEffect } from 'react';
import baseURL from '../../services/baseURL';
//import jquery from 'jquery';
import RefreshToken from '../../services/RefreshToken';
//import 'select2';
//import 'select2/dist/css/select2.css';
import TagsInput from 'react-tagsinput';
//import 'react-tagsinput/react-tagsinput.css';
// import 'bootstrap-filestyle/src/bootstrap-filestyle.js'; 
import { Redirect, NavLink } from "react-router-dom";
//import './Basvurular.css';
//import AuthContext from '../../services/AuthContext';
import request from '../../services/Request';
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
import TabloButonlari from '../TabloButonlari';
import SayfalamaButonlari from '../SayfalamaButonlari';
import axios from 'axios'; 
import Context from '../../context/store';

const btnstyle = { backgroundColor: 'transparent', border: 'none', padding: '0px', margin: '0px' };
const downclass = "fa fa-angle-down";
const upclass = "fa fa-angle-up";

function OrderComp(props) {
  if (props.col === props.sortingProperty) {
    return (
      <button className={props.sortedAscending ? downclass : upclass} style={btnstyle}></button>
    );
  }
  else {
    return (
      <div></div>
    );
  }
}


function firstLetter(str) {
  var parcalar = str.split(" ");
  for (var i = 0; i < parcalar.length; i++) {
    var j = parcalar[i].charAt(0).toLocaleUpperCase('TR');
    parcalar[i] = j + parcalar[i].substr(1).toLocaleLowerCase('TR');
  }
  return parcalar.join(" ");
}

const filtre_options = {
  placeholder: "Seçiniz",
  language: {
    noResults: function () {
      return "Sonuç bulunamadı...";
    }
  },
};

function BasvurularCard() {
  const {state, dispatch} = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [filtreacik, setFiltreacik] = useState(false);
  const [RedirectURL, setRedirectURL] = useState('');
  const [filtre, setFiltre] = useState('ADI / ÜNVANI');
  const [elemanlar, setElemanlar] = useState(['ADI / ÜNVANI', 'BAŞVURU NO', 'TC KİMLİK / VERGİ NO', 'ŞEHİR', 'İLÇE', 'MAHALLE / KÖY', 'TELEFON', 'TÜZEL KİŞİ']);


  const [FiltreControl, setFiltreControl] = useState(false);
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [recordsTotal, setRecordsTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [sortingProperty, setSortingProperty] = useState('id');
  const [sortingColumnIndex, setSortingColumnIndex] = useState(0);
  const [sortedAscending, setSortedAscending] = useState(true);
  const [HedefUrl, setHedefUrl] = useState('YOK');
  const [popoverOpen, setpopoverOpen] = useState(false);
  const [errorStatus, setErrorStatus] = useState('');  

  //const context = useContext(AuthContext);
  
  class CacheItem {
    constructor(url, param,data) {
      this.url = url;
      this.param = param;
      this.data = data;
      this.time=new Date();
    }
  }

  const ekleClick = () => {
    setRedirectURL('/basvurular/ekle');
  };
  
  const detayClick = (id) => {
    setRedirectURL('/basvurular/detay/' + id);
  };

  const duzenleClick = (e, id) => {
    e.preventDefault();
    setRedirectURL('/basvurular/duzenle/' + id);
  };

  const handleChange = (tags) => {
    state.basvurularFilter = tags;
    fetchData(1, recordsPerPage, sortingProperty, sortingColumnIndex, sortedAscending);
  }

  const hasClass = (ele, cls) => {
    return ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
  }

  const getbasvurulardata_Basarisiz = (status) => {
    setErrorStatus(status);
    setLoading(false);
    setError(true);
  }


  const getbasvurulardata_Basarili_Offline = () => {
    if (currentPage >= Math.ceil(state.DATA.recordsTotal / recordsPerPage)) setCurrentPage(Math.ceil(state.DATA.recordsTotal / recordsPerPage));
    
    setData(state.DATA);
    setRecordsTotal(state.DATA.recordsTotal);
    setTotalPages(Math.ceil(state.DATA.recordsTotal / recordsPerPage));
    setLoading(false);
    //context.setLoader(false);
}



  const getbasvurulardata_Basarili = (response) => {
    
    if (currentPage >= Math.ceil(response.data.recordsTotal / recordsPerPage)) setCurrentPage(Math.ceil(response.data.recordsTotal / recordsPerPage));
    setLoading(false);
    //context.setLoader(false);
    setData(response.data);
    setRecordsTotal(response.data.recordsTotal);
    setTotalPages(Math.ceil(response.data.recordsTotal / recordsPerPage));
  }


  const DownloadXls = () => {
    $.ajax({
      type: 'GET',
      url: baseURL + "/exportxlsx",
      headers: {
        "Authorization": 'Bearer ' + localStorage.token
      },
      xhrFields: { responseType: 'blob' },
      success: function (data) {
        var blob = new Blob([data]);
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = "text.xlsx";
        link.click();
      },
      //
      error: function (jqXHR, textStatus, errorThrown) {
        if (jqXHR.status == 401) {
          RefreshToken(this);
        };
      }
      //
    });
  }

  const filtreSelected = () => {
    setFiltre($('#zz1 :selected').text());
  }

  const getsearchingURL = () => {

    var ftl = $('#zz1 :selected').text();

    var ret = "";

    if (ftl == "ADI / ÜNVANI") {

      ret = "adiunvaniara";
    }

    if (ftl == "basvurular NO") {

      ret = "basvurularnoara";
    }

    if (ftl == "TC KİMLİK / VERGİ NO") {

      ret = "tckimlikverginoara";
    }

    if (ftl == "ADI / ÜNVANI") {

      return "adiunvaniara";
    }

    if (ftl == "ŞEHİR") {

      ret = "sehirara";
    }

    if (ftl == "İLÇE") {

      ret = "ilceara";
    }

    if (ftl == "MAHALLE / KÖY") {

      ret = "mahallekoyara";
    }

    if (ftl == "TELEFON") {

      ret = "telefonnoara";
    }

    if (ftl == "TÜZEL KİŞİ") {

      ret = "YOK";
    }

    return ret;
  }

  const degerSelected = () => {
    if ($('#tt1 :selected').text()) {
      $('#FiltreEkle').prop("disabled", false);
    }
  }

  const LoadUploadFile = () => {
    $.ajax({
      url: "/components/dosyayukle.html",
      type: "get",
      success: function (src) {
        alert(src);
        // $("#kayitFormu").html(Handlebars.compile(src));
        // $('#BSbtndanger').filestyle({
        //   buttonName: 'btn-warning',
        //   buttonText: 'Dosya Seç'
        // });
        // $("#kayitFormu").modal("show");
      },
      error: function () {
      }
    });
  }

  const OncekiSayfa = () => {
    if (currentPage - 1 >= 1) {
      //context.setLoader(true);
      //setLoading(true);
      setCurrentPage(parseInt(currentPage) - 1);
      //fetchData(currentPage, recordsPerPage, sortingProperty, sortingColumnIndex, sortedAscending);
    }
    else {

    }
  }

  
  const SonrakiSayfa = () => {
    if (currentPage + 1 <= totalPages) {
      //context.setLoader(true);
      //setLoading(true);
      setCurrentPage(parseInt(currentPage) + 1);
      //fetchData(currentPage, recordsPerPage, sortingProperty, sortingColumnIndex, sortedAscending); 
    }
    else {

    }
  }

  const IlkSayfa = () => {
    if (currentPage != 1) {
      //context.setLoader(true);
      //setLoading(true);
      setCurrentPage(1);
      //fetchData(currentPage, recordsPerPage, sortingProperty, sortingColumnIndex, sortedAscending);
    }
  }

  const SonSayfa = () => {
    if (currentPage != totalPages) {
      //context.setLoader(true);
      //setLoading(true);
      setCurrentPage(totalPages);
      //fetchData(currentPage, recordsPerPage, sortingProperty, sortingColumnIndex, sortedAscending);
    }
  }

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {
    ReactTooltip.rebuild();

    $(document).on('select2:open', () => {
      document.querySelector('.select2-search__field').focus();
    });

    fetchData(currentPage, recordsPerPage, sortingProperty, sortingColumnIndex, sortedAscending);
   
    return () => {
     //unmount
    }

  }, [state.basvurularFilter, currentPage, recordsPerPage, sortingProperty, sortingColumnIndex, sortedAscending]);

  
  const fetchData = (currentPage, recordsPerPage, sortingProperty, sortingColumnIndex, sortedAscending) => {
    
    var flt = "";
    for (var i = 0; i < state.basvurularFilter.length; i++) {
      if (i > 0) flt = flt + "," + state.basvurularFilter[i]; else flt = flt + state.basvurularFilter[i];
    }
    flt = flt.replace("...", "");

    state.getrehberdata_postparam= { 'filtre': flt, 'currentPage': currentPage, 'recordsPerPage': recordsPerPage, 'sortingProperty': sortingProperty, 'sortingColumnIndex': sortingColumnIndex, 'sortedAscending': sortedAscending };

    request('GET', '/getbasvurulardata', state.getrehberdata_postparam, getbasvurulardata_Basarili, getbasvurulardata_Basarisiz, '');
        //
      //   axios.request({
      //     method: "GET",
      //     url: "http://back.alfacert.com.tr/getbasvurulardata",
      //     params: context.getrehberdata_postparam,
      // }).then((response) => {
      //   if (currentPage >= Math.ceil(response.data.recordsTotal / recordsPerPage)) setCurrentPage(Math.ceil(response.data.recordsTotal / recordsPerPage));
      //   setLoading(false);
      //   context.setLoader(false);
      //   setData(response.data);
      //   setRecordsTotal(response.data.recordsTotal);
      //   setTotalPages(Math.ceil(response.data.recordsTotal / recordsPerPage));
      // })
    
      //


    }


  const handleDetayButtonClick = (e) => {
    e.preventDefault();
    setRedirectURL('/rehber/detay/' + e.currentTarget.value);
  }

  const UnvanKisalt = (value) => {
    var a = value.length > 20 ?
      value.substr(0, 20) + '…' :
      value
    return a;
  }

  const TelefonFormat = (value) => {
    var st = value;
    var data = value;
    if (data != null && data.length == 10) st = '(' + data.substr(0, 3) + ') ' + data.substr(3, 3) + ' ' + data.substr(6, 4);
    if (data != null && data.length == 7) st = '(242) ' + data.substr(0, 3) + ' ' + data.substr(3, 7);
    return st;
  }

  const ShowHideFiltre = () => {
    $('#FiltreEkle').prop("disabled", true);
    $("#degerdiv").show();
    setFiltreacik(!filtreacik);
  }

  const FiltreEkle = () => {
    var flt = $('#tt1 :selected').text();
    flt = flt.length > 20 ? flt.substr(0, 20) + '...' : flt;
    var Filtre = $('#zz1 :selected').text() + " = " + flt;

    var fltr = [];
    if (state.basvurularFilter.length > 0) fltr = state.basvurularFilter;
    fltr.push(Filtre);
    state.basvurularFilter = fltr;

    document.getElementsByClassName("react-tagsinput")[0].style.display = 'block';

    ShowHideFiltre();
    
    setCurrentPage(1);
    //    fetchData(currentPage, recordsPerPage, sortingProperty, sortingColumnIndex, sortedAscending);
  }



  const BasvurularFiltre = () => {
    return (
      <div id="basvurular_filtre" style={{ display: filtreacik ? 'block' : 'none' }}>
        <div className="row">
          <div className="col-sm-4">
            <div className="form-group">

              <label className="control-label">Filtre</label>
              {/* <Select2
                id="zz1"
                className="form-control"
                defaultValue={filtre}
                options={filtre_options}
                data={elemanlar}
                onChange={filtreSelected}
                style={{ width: "100%" }} /> */}
            </div>
          </div>

          <div className="col-sm-4">
            <div className="form-group" id="degerdiv">
              <label className="control-label">{firstLetter(filtre.toLocaleLowerCase())}</label>
              {/* <Select2
                id="tt1"
                className="form-control"
                // data={this.state.ilceler}
                onChange={degerSelected}
                style={{ width: "100%" }}
                // defaultValue={this.state.ilce}
                options={{
                  placeholder: "Seçiniz",
                  minimumInputLength: 1,
                  language: {
                    noResults: function () {
                      return "Sonuç bulunamadı...";
                    },
                    errorLoading: function () {
                      return "Sonuç bulunamadı...";
                    },
                    searching: function () {
                      return "Aranıyor...";
                    },
                    inputTooShort: function (args) {
                      var remainingChars = args.minimum - args.input.length;

                      var message = 'En az ' + remainingChars + ' karakter daha girmelisiniz';

                      return message;
                    },
                  },
                  width: 'resolve',

                  ajax: {
                    url: baseURL() + "/" + getsearchingURL(),
                    type: "POST",
                    datatype: "json",
                    headers: {
                      "Authorization": 'Bearer ' + localStorage.token
                    },
                    data: function (params) {

                      return {
                        term: params.term,
                      };
                    },
                    processResults: function (data) {
                      $("#tt1").empty();
                      return {
                        results: $.map(data, function (i, item) {
                          return {
                            //text: i.length > 20 ? i.substr(0, 20) + '…' : i,
                            text: i,
                            id: i,//item
                          }
                        })

                      };
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                      if (jqXHR.status == 401) {
                        RefreshToken(this);
                      }
                    },

                  }
                }}
              /> */}
            </div>

          </div>

          <div className="col-sm-4">
            <div className="form-group">
              <br />
              <div className="ddan">
                <button type="button" id="FiltreEkle" onClick={FiltreEkle}
                  className="btn btn-sm btn-link gbtn">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ekle&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
                <button onClick={ShowHideFiltre} className="btn btn-sm btn-link gbtn"
                  data-dismiss="modal">&nbsp;&nbsp;Vazgeç&nbsp;&nbsp;</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const Template_Table = () => {
    return (
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th className="no">NO <OrderComp col={'id'} sortingProperty={sortingProperty} sortedAscending={sortedAscending}></OrderComp></th>
              <th className="detay" ></th>
              <th>MÜŞTERİ NO <OrderComp col={'musno'} sortingProperty={sortingProperty} sortedAscending={sortedAscending}></OrderComp></th>
              <th className="adiunvani">BAŞVURAN <OrderComp col={'unvaniadi'} sortingProperty={sortingProperty} sortedAscending={sortedAscending}></OrderComp></th>
              <th>TARİH <OrderComp col={'tarih'} sortingProperty={sortingProperty} sortedAscending={sortedAscending}></OrderComp></th>
              <th>SERTİFİKA <OrderComp col={'turu'} sortingProperty={sortingProperty} sortedAscending={sortedAscending}></OrderComp></th>
              <th>SEÇENEK<OrderComp col={'turu'} sortingProperty={sortingProperty} sortedAscending={sortedAscending}></OrderComp></th>
             </tr>
          </thead>
          {data != null ? data.data.map((val, key) => {
            return (
              <tr key={key}>
                <td data-column="NO">{val.id.toString()}</td>
                <td data-column="DETAY"><span data-column_index="0" className="cell-content"><span className="padding-right-5"><i className="circle-plus"></i></span><span className="custom-content"><button className="myb btn btn-light px-1 py-0 mt-1" data-tip="Üreticiler" value={val.id} onClick={handleDetayButtonClick}><i>...</i></button></span></span></td>
                <td data-column="MÜŞTERİ NO">{val.musterino == null ? "" : "ECS-"+val.musterino.toString()}</td>
                <td data-column="ADI SOYADI / ÜNVANI" className="me-2 fs-7 fw-bold">{UnvanKisalt(val.unvaniadi)}</td>
                <td data-column="TARIH">{new Date(val.tarih).toLocaleDateString()}</td>
                <td data-column="TURU">{val.sertifikatipi}</td>
                <td data-column="TURU">{val.secenek}</td>
                </tr>
            )
          }) : null}
        </table>
      </div>
    )
  }

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  const Template = () => {

   
  }

  if (RedirectURL)
    return <Redirect push to={RedirectURL} />;

    if (error) {
      //setLoading(false);
      if (errorStatus!="402") return <div>Bir hata oluştu. {errorStatus}</div>;
  }

  // if (loading) {

  //   if (context.HTML == null) {
  //     return <div className="loaderdiv"></div>;
  //   }
  //   else {
  //     context.setLoader(true);
  //     return context.HTML;
  //   }
  // }

  //context.HTML = Template();
 // context.setLoader(false);
 //var recordsTotal = data == null ? 0 : data.recordsTotal;

    return (

      !loading &&  (errorStatus!="402") ?  <div className="card card-outline cardm" >
  

                    <div className="card-header">

                      <div className="tagWrapperDiv" style={{ display: state.basvurularFilter.length > 0 ? 'block':'none' }}>

                        <TagsInput value={state.basvurularFilter} onChange={handleChange} />

                      </div>

                      <TabloButonlari EkleClick={ekleClick} DownloadXls={DownloadXls} ShowHideFiltre={ShowHideFiltre} />

                      <SayfalamaButonlari IlkSayfa={IlkSayfa} OncekiSayfa={OncekiSayfa} SonrakiSayfa={SonrakiSayfa} SonSayfa={SonSayfa} currentPage={currentPage} recordsTotal={data == null ? 0 : data.recordsTotal} recordsPerPage={recordsPerPage} />

                      <div id="sildiv" className=".modal">
                        <div className="sdv">

                          <div className="alert alert-secondary">
                            <button onClick="FirmaSil();" className="btn btn-xs btn-danger pull-right sbtn"
                            >Sil</button>
                            <button onClick="ModalKapat();$('#sildiv').hide();"
                              className="btn btn-xs btn-secondary pull-right">Vazgeç</button>

                            <strong>Uyarı: </strong> <a id="sillabel"></a>
                          </div>
                        </div>
                      </div>

                    </div>

                    {/* {this.state.FiltreControl ? <basvurular_Filtre func={this.ShowHidebasvurularFiltre} funcbasvurularFiltreEkle={this.basvurularFiltreEkle} /> : null} */}
                    {BasvurularFiltre()}

                    {Template_Table()}

                    <div className="cardfooter">
                      <div className="float-right">{numberWithCommas(recordsTotal)} kayıttan  {(currentPage - 1) * recordsPerPage == 0 ? 1 : numberWithCommas((currentPage - 1) * recordsPerPage + 1)} ile {(currentPage - 1) * recordsPerPage + recordsPerPage > recordsTotal ? numberWithCommas(recordsTotal) : numberWithCommas((currentPage - 1) * recordsPerPage + recordsPerPage)} arası kayıtlar gösteriliyor.</div>
                    </div>

                    <div className="modal fade text-left" id="backdrop" role="dialog" aria-labelledby="myModalLabel4"
                      aria-hidden="true">
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel4">Filtre Ekle</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body mbd">

                            <div className="form-group">
                              <label className="control-label">Alan</label>
                              <select id="zz" tabIndex="-1" className="form-control select2 select2-hidden-accessible"
                                aria-hidden="true">
                                <option>SEÇİNİZ</option>
                                <option>basvurular NO</option>
                                <option>TC KİMLİK/VERGİ NO</option>
                                <option>ADI / ÜNVANI</option>
                                <option>ŞEHİR</option>
                                <option>İLÇE</option>
                                <option>MAHALLE / KÖY</option>
                                <option>TELEFON</option>
                              </select>
                            </div>
                            <div className="form-group">
                              <label className="form-control-label">Değer</label>
                              <select disabled id="tt" tabIndex="-1" className="form-control select2 select2-hidden-accessible"
                                aria-hidden="true">
                              </select>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn grey btn-secondary" style={{ backgroundColor: 'red' }} data-dismiss="modal">Vazgeç</button>
                            <button type="button" id="FiltreEkle" onClick="FiltreEkle()" className="btn btn-info">Ekle</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ReactTooltip place="bottom" type="dark" className='customtooltip'
          textColor='white' backgroundColor='#44567c' effect='solid' /> 
                    </div>:<div className="card card-outline cardm" style={{ width: "98%",height:"560px"}}>
<div className="child">
{ errorStatus!="402" ?
<div id="loadericondiv2" style={{ display: 'block',color:'lightgray',fontSize:'50px',marginTop:'0px' }} className="float-left">
 <i id="loadericon" className="fas fa-sync fa-spin"></i>
</div>:<div  style={{ display: 'block',color:'lightgray',fontSize:'20px',marginTop:'0px',marginLeft:'-150px' }} className="float-left">
 <i id="loadericon">Bu bölümü görüntüleme yetkiniz yok !</i>
</div> }

</div>

     

</div>
    );

}

export default BasvurularCard; 