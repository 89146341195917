import React from 'react';

function TabloButonlari(props) {
    return (
        <div id="buttondiv">
          <div className="btn-group" style={{ marginBottom: '5px' }}>
            <button className="btn btn-sm btn-link gbtn" data-tip="Ekle"
              onClick={props.EkleClick}><i className="la la-plus"></i></button>
            <button className="btn btn-sm btn-link gbtn" data-tip="Excel Formatında İndir"
              onClick={props.DownloadXls}><i className="far fa-file-excel"></i></button>
            <button id="PopoverFiltre" className="btn btn-sm btn-link gbtn" data-tip="Filtre Ekle"
              onClick={props.ShowHideFiltre}><i className="la la-filter"></i></button>
          </div>
        </div>
      )
    
}

export default TabloButonlari;