function LoggedIn() {
    if (localStorage.token) {
        return true;
    }
    else {
        return false;
    }
}

export default LoggedIn;
