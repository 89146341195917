import {
  Switch,
  Route,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import React, { useContext, useState } from 'react';
import toastr from 'toastr';
import AuthContext from '../services/AuthContext';
import './Reset.css';
import baseURL from '../services/baseURL';
import $ from 'jquery';


function Reset() {
  let { path } = useRouteMatch();
  return (
    <div>
      <Switch>
        <Route path={`${path}/:id1/:id2`}>
          <Child />
        </Route>
      </Switch>
    </div>
  );
}

function BilgileriKaydet_Basarili(response) {
  toastr.options = {
      "positionClass": "toast-top-center",
      "preventDuplicates": true,
  }
  toastr["success"]('Parolanız Oluşturuldu...');
}


function formPreventDefault(e) {
  e.preventDefault();
}


function ParolaOlustur(id1,id2,yeniparola1,yeniparola2) {

  var form = document.getElementById('myForm');
  var isValid = form.checkValidity();
  if (isValid) {
//

$.ajax({
  type: 'POST',
  url: baseURL() + "/setpas",
  data: { 'ui64': id1, 'resettoken': id2, 'new_password1': yeniparola1, 'new_password2': yeniparola2 },
  success: function (data) {
    //alert(data.sonuc);
   
  toastr.options = {
    "closeButton": true,
    "positionClass": "toast-top-center",
    "preventDuplicates": true,
  }
  
  toastr.options.onHidden = function () {
    window.location.href = '/';
  };

  toastr["success"](data.sonuc);

   //$('#id_new_password1').val("");
   //$('#id_new_password2').val("");
  },
  //
  error: function (jqXHR, textStatus, errorThrown) {
    //alert(errorThrown);
    if (jqXHR.status === 401) {
     //RefreshToken(this);
    };
    if (jqXHR.status === 460) {
      toastr.options = {
        "positionClass": "toast-top-center",
        "preventDuplicates": true,
      }
      toastr["error"]('Girdiğiniz parolalar birbiri ile eşleşmiyor...') 
    };

    if (jqXHR.status === 461) {
      toastr.options = {
        "positionClass": "toast-top-center",
        "preventDuplicates": true,
      }
      toastr["error"]('Link geçersiz...') 
    };

  }
  //
});

//

  }
}


function Child() {
  let { id1, id2 } = useParams();
  const [yeniparola1, setyeniparola1] = useState("");
  const [yeniparola2, setyeniparola2] = useState("");
  const context = useContext(AuthContext);
  if (context.isLoggedIn) {
    context.user='';
    //context.setCompany('');
    localStorage.clear();
  }
  
  const olustur = () => ParolaOlustur(id1,id2,yeniparola1,yeniparola2);

  return (

    <div>

      <div className="card reset">
        <div className="card-header">
          <div className="card-title">
            <h3>{context.user}</h3>
          </div>

        </div>
        <div className="card-content collapse show">

        </div>
        <div className="row">
          <div className="col-12">
            <div className="card snone">
              <div className="card-content">
                <div className="card-body">
                  <form id="myForm" method="post" onSubmit={e => e.preventDefault()}>
                    <table className="table reset">
                      <tbody><tr></tr>

                        <tr>
                          <td>
                            <label>    Yeni Parola</label>
                            <input id="id_new_password1" type="password" className="form-control" name="new_password1" value={yeniparola1} onChange={e => setyeniparola1(e.target.value)} title="" placeholder="" required="required" minlength="5" maxlength="50" />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label>   Yeni Parola Tekrar</label>
                            <input id="id_new_password2" type="password" className="form-control" name="new_password2" value={yeniparola2} onChange={e => setyeniparola2(e.target.value)} title="" placeholder="" required="required" minlength="5" maxlength="50" />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <button className="btn btn-secondary" onClick={olustur}>Kaydet</button>
                          </td>
                        </tr>
                      </tbody></table>

                    {/* <input type="hidden" id="resettoken"  value={{resettoken}}/>
                                            <input type="hidden" id="ui64"  value={{ui64}}/> */}

                  </form>

                  <p>
                  </p>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>


    </div>

  );
}
export default Reset;