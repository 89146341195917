import React, { useContext, useState, useEffect, useRef } from 'react';
//import './Hesabim.css';
import AuthContext from '../services/AuthContext';
import { showSuccessmessage, showErrormessage } from '../services/Messages.js';
import api from '../services/Api';
import { useQuery, QueryClient, QueryClientProvider } from "react-query";


function HesabimBilgiCard() {
    const context = useContext(AuthContext);
    return (
        <QueryClientProvider client={context.queryClient}>
            <HesabimBilgiCard2 />
        </QueryClientProvider>
    )
}

function HesabimBilgiCard2() {
    const [error, setError] = useState(false);
    const [errorStatus, setErrorStatus] = useState('');

    const fetchHesabimData = async () => {
        const { data } = await api.get('/bilgilerim');
        return data;
    };

    const { isLoading, isFetching, status, refetch, data } = useQuery('queryClient', fetchHesabimData, {
            onSuccess: (data) => {

            },
            onError: (error) => {
                console.log(error.message)
            },
        });

    useEffect(() => {

    }, []);

    const formPreventDefault = (e) => {
        e.preventDefault();
    }

    if (error) {
        //setLoading(false);
        if (errorStatus != "402") return <div>Bir hata oluştu. {errorStatus}</div>;
    }

    return (

        !isLoading && (errorStatus != "402") ? <div className="card card-outline" style={{ minHeight: "110px" }}>

            <div id="info" className="card  me-xl-3 mb-md-0 mb-6" style={{ padding: "20px"}}>

                <div className="card-body">
                                    <div style={{ width: '100%' }}>
                                    <label className="fw-bold mb-2 truncate" style={{ margin: "10px"}}>Kullanıcı Adı : {data.email}</label>
                                    </div>
                                    <div style={{ width: '100%' }}>
                                    <label className="fw-bold mb-2 truncate" style={{ margin: "10px" }}>Görev&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : {data.rol}</label>    
                                    </div>
                                    <div style={{ width: '100%' }}>
                                    <label className="fw-bold mb-2 truncate" style={{ margin: "10px" }}>Firma&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : {data.firma}</label>    
                                    </div>
                </div>
            </div>

        </div> : <div className="card card-outline cardm" style={{ minHeight: "110px" }}>
            <div className="child">
                {errorStatus != "402" ?
                    <div id="loadericondiv2" style={{ display: 'block', color: 'lightgray', fontSize: '50px', marginTop: '-10px' }} className="float-left">
                        <i id="loadericon" className="fas fa-sync fa-spin"></i>
                    </div> : <div style={{ display: 'block', color: 'lightgray', fontSize: '20px', marginTop: '0px', marginLeft: '-150px' }} className="float-left">
                        <i id="loadericon">Bu bölümü görüntüleme yetkiniz yok !</i>
                    </div>}

            </div>
        </div>
    );
}

export default HesabimBilgiCard;