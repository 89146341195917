import React, { Component } from 'react';
import baseURL from '../services/baseURL';
import $, { param } from 'jquery';
import RefreshToken from '../services/RefreshToken';
import AuthContext from '../services/AuthContext';

class RegisterProducer extends React.Component {
  static contextType = AuthContext;

  constructor(props) {

    super(props);
  }

  componentDidMount() {

    $.ajax({
        type: 'POST',
        url: baseURL() + "/register-producer",
        headers: {
          "Authorization": 'Bearer ' + localStorage.token
        },
        data: {"regno":"ECAS-TUR 21111111","adi": "ELİF","soyadi":"ÖZGÜR","sehir":"ANTALYA","ulke":"TUR"},
        success: function (data) {
          
          if (data.sonuc==="ok") alert("kayıt başarılı, ggn ="+data.ggn)
          
          if (data.sonuc==="failed") {
            if (data.mesaj==="Invalid registration number.") {
              alert("Geçersiz registration number, ggn ="+data.ggn)
            }
        } 
        else 
      {
        alert(data.sonuc);
      }

      }.bind(this),
        //
        error: function (jqXHR, textStatus, errorThrown) {
            if (jqXHR.status === 200) {
              };
          if (jqXHR.status === 401) {
            RefreshToken(this);
          };
        }
        //
      });

  }

  render() {

    return (

        <div>
    
        </div>
    );
}

}

export default RegisterProducer
