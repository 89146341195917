import $ from 'jquery';
import baseURL from './baseURL';

function logout() {
    localStorage.clear();
    window.location.href = '/oturum-ac';
  }

function RefreshToken(config) {
    $.ajax({
      type: 'GET',
      async:false,
      data: {  email:"emrah@tekinarslan.com", rol:localStorage.rol,firma:localStorage.firma,refreshToken: localStorage.refresh },
      url: baseURL() + '/relogin',
      success: function (data) {
        //alert(data.accessToken);
        localStorage.token = data.accessToken;
        //localStorage.refresh = data.refresh;

        // $.ajaxSetup({
        //   beforeSend: function (xhr) {
        //     xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.token);
        //   }
        // });
        // $.ajax(config);
      },
      error: function (jqXHR, textStatus, errorThrown) {
       // alert(jqXHR.status);
        if (jqXHR.status === 401) {
          logout();
        };
        if (jqXHR.status === 400) {
          logout();
        };
      }
    });
  }
  
  export default RefreshToken;