import axios from 'axios';
import baseURL from './baseURL';
import toastr from 'toastr'

function logout() {
    localStorage.clear();
    window.location.href = '/oturum-ac';
}

function recall(oldconfig,oldsuccmsg,succfunc)
{
    axios(oldconfig).then((response) => {

        succfunc(response,oldsuccmsg);

    },
        (error) => {
            var status = error.response.status
            if (status === 402) {
                toastr.options = {
                    "positionClass": "toast-top-center",
                    "preventDuplicates": true,
                }
                toastr["error"]('Yetkisiz...')
            }
            else {
                toastr.options = {
                    "positionClass": "toast-top-center",
                    "preventDuplicates": true,
                }
                toastr["error"]('İşlem başarısız oldu')
            };
        }
    );
}

function refresh_token(config,msg,succfunc,errorfunc) {
   
    axios.post(baseURL() + '/relogin',JSON.stringify({  email:localStorage.userName, rol:localStorage.rol,firma:localStorage.firma,refreshToken: localStorage.refresh }),  
        {headers: {
            // Overwrite Axios's automatically set Content-Type
            'Content-Type': 'application/json'
          }}
    ).then((response) => {
  
        localStorage.token = response.data.accessToken;
        //localStorage.refresh = response.refreshToken;
        config.headers["Authorization"]='Bearer ' + localStorage.token;
        //alert(config.headers["Authorization"]);
        recall(config,msg,succfunc);
    },
        (error) => {
            var status = error.response.status
            if (status === 401) {
                logout();
              }
              else
              {
                errorfunc(status);
              }
              if (status === 400) {
                logout();
              };
        }
    );
}

export default refresh_token;
