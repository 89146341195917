
  class CacheItem {
    constructor(url, param,data) {
      this.url = url;
      this.param = param;
      this.data = data;
      this.time=new Date();
    }
  }

export default CacheItem;