
import React, { useContext, useState, useEffect, useRef } from 'react';
//import './Hesabim.css';
import toastr from 'toastr'
import AuthContext from '../services/AuthContext';
import request from '../services/Request';
import api from '../services/Api';
import { showSuccessmessage, showErrormessage } from '../services/Messages.js';
import $ from 'jquery';

function HesabimSifreDegistirCard() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorStatus, setErrorStatus] = useState('');
    const [eskiparola, setEskiparola] = useState('');
    const [yeniparola1, setYeniparola1] = useState('');
    const [yeniparola2, setYeniparola2] = useState('');

    const context = useContext(AuthContext);
    const button_ref = useRef(null);


    const replaceValidationUI = (form) => {
        // Suppress the default bubbles
        form.addEventListener("invalid", function (event) {
            event.preventDefault();
        }, true);

        // Support Safari, iOS Safari, and the Android browser—each of which do not prevent
        // form submissions by default
        form.addEventListener("submit", function (event) {
            if (!this.checkValidity()) {
                event.preventDefault();
            }
        });

        var submitButton = form.querySelector("button:not([type=button]), input[type=submit]");

        if (submitButton) {
        submitButton.addEventListener("click", function (event) {
            var invalidFields = form.querySelectorAll(":invalid"),
                errorMessages = form.querySelectorAll(".error-message"),
                parent;

            // Remove any existing messages
            for (var i = 0; i < errorMessages.length; i++) {
                errorMessages[i].parentNode.removeChild(errorMessages[i]);
            }

            for (var i = 0; i < invalidFields.length; i++) {
                parent = invalidFields[i].parentNode;
                if (invalidFields[i].validationMessage.search("eşleştirin") > -1) {

                    parent.insertAdjacentHTML("beforeend", "<div class='error-message'>" +
                        "En az bir rakam, bir büyük ve küçük harf ve en az 8 veya daha fazla karakter içermelidir." +
                        "</div>");
                }
                else {
                    parent.insertAdjacentHTML("beforeend", "<div class='error-message'>" +
                        invalidFields[i].validationMessage +
                        "</div>");
                }
            }

            // If there are errors, give focus to the first invalid field
            if (invalidFields.length > 0) {
               // invalidFields[0].focus();
            }
        });
    }
    }

    useEffect(() => {

        var forms = document.querySelectorAll("form");
        for (var i = 0; i < forms.length; i++) {
            replaceValidationUI(forms[i]);
        }

        return () => {
            };

    }, []);


    const ParolaDegistir_Basarili = (response) => {

       // setLoading(false);
       document.getElementById("kt_button_2").removeAttribute("data-kt-indicator");
        showSuccessmessage(response.data.sonuc);

        // $('#id_old_password').val("");
        // $('#id_new_password1').val("");
        // $('#id_new_password2').val("");
    }

    const ParolaDegistir_Basarisiz = (status) => {
//alert(status);
       // setLoading(false);
       document.getElementById("kt_button_2").removeAttribute("data-kt-indicator");
        if (status === 460) {
            toastr.options = {
                "positionClass": "toast-top-center",
                "preventDuplicates": true,
            }
            toastr["error"]('Girdiğiniz parolalar birbiri ile eşleşmiyor...')
        };
        if (status === 461) {
            toastr.options = {
                "positionClass": "toast-top-center",
                "preventDuplicates": true,
            }
            toastr["error"]('Eski parola yanlış girildi...')
        };
        //this.setState({ error:true,loading:false });
    }


    const ParolaDegistir = async (e) => {
        
        var form = document.getElementById('myForm2');

        var isValid = form.checkValidity();

        if (isValid) {   
            //setLoading(true);
            document.getElementById("kt_button_2").setAttribute("data-kt-indicator", "on");

                try {
                    const response=await api.post(`/chpas`, { 'old_password': eskiparola, 'new_password1': yeniparola1, 'new_password2': yeniparola2 });
                    document.getElementById("kt_button_2").setAttribute("data-kt-indicator", "off");
                 //   document.getElementById("id_old_password").value="";
                 //   document.getElementById("id_new_password1").value="";
                  //  document.getElementById("id_new_password2").value="";
                  setEskiparola("");
                  setYeniparola1("");
                  setYeniparola2("");
                  showSuccessmessage("Parolanız başarıyla değiştirildi");
                } catch (error) {

                    //error.response.status 
                    document.getElementById("kt_button_2").setAttribute("data-kt-indicator", "off");
                    showErrormessage(error.response.data.errormessage);

                }

        }
    }

    const formPreventDefault = (e) => {
        e.preventDefault();
    }

    if (error) {
        //setLoading(false);
        if (errorStatus != "402") return <div>Bir hata oluştu. {errorStatus}</div>;
    }


    return (

        !loading && (errorStatus != "402") ?


            <div id="info" className="card  me-xl-3 mb-md-0 mb-6">
                <form id="myForm2" method="post" autocomplete="off" onSubmit={formPreventDefault}>

                    <div className="card-header">

                        <div className="card-title">
                            <h4 className="fw-bolder">Parola</h4>
                        </div>

                        <div className="card-toolbar">

                            <button ref={button_ref} onClick={(e) => ParolaDegistir(e.target)} className="btn btn-primary btn-sm" id="kt_button_2">
                                <span className="indicator-label">
                                    Kaydet
                                </span>
                                <span className="indicator-progress">
                                    Kaydet<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>

                    </div>

                    <div className="card-body">

                        <div className="d-flex flex-wrap py-5">

                            <div className="form-group mdv" style={{ width: "100%" }}>
                                <label class="required fs-6 fw-bold mb-2">Mevcut Parola</label>
                                <input id="id_old_password" type="password" className="form-control form-control-solid" name="old_password" value={eskiparola} onChange={e => setEskiparola(e.target.value)} title=""
                                    placeholder="" required="required"  maxlength="50" />
 
                            </div>

                            <div className="form-group mdv" style={{ width: "100%" }}>
                                <label class="required fs-6 fw-bold mb-2"> Yeni Parola</label>
                                <input id="id_new_password1" type="password" className="form-control form-control-solid" name="new_password1" value={yeniparola1} onChange={e => setYeniparola1(e.target.value)}
                                    title="" placeholder="" required="required" minlength="8" maxlength="50" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"/>
                            </div>

                            <div className="form-group mdv" style={{ width: "100%" }}>
                                <label class="required fs-6 fw-bold mb-2"> Yeni Parola Tekrar</label>
                                <input id="id_new_password2" type="password" className="form-control form-control-solid" name="new_password2" value={yeniparola2} onChange={e => setYeniparola2(e.target.value)}
                                    title="" placeholder="" required="required" minlength="8" maxlength="50" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"/>
                            </div>

                        </div>
                    </div>
                </form>
            </div>

            : <div >
                <div className="child">
                    {errorStatus != "402" ?
                        <div id="loadericondiv2" style={{ display: 'block', color: 'lightgray', fontSize: '50px', marginTop: '0px' }} className="float-left">
                            <i id="loadericon" className="fas fa-sync fa-spin"></i>
                        </div> : <div style={{ display: 'block', color: 'lightgray', fontSize: '20px', marginTop: '0px', marginLeft: '-150px' }} className="float-left">
                            <i id="loadericon">Bu bölümü görüntüleme yetkiniz yok !</i>
                        </div>}

                </div>
            </div>

    );

}

export default HesabimSifreDegistirCard;







