import React, {useState} from "react";
import LoggedIn from './LoggedIn';
import $ from 'jquery';import { useQuery,QueryClient, QueryClientProvider } from "react-query";
//const queryClient = new QueryClient()

const AuthContext = React.createContext({

     setQueryClient: () => {},
     currentPageName: "",
     setCurrentPageName: () => {},
     currentSubPageName: "",
     setCurrentSubPageName: () => {},
     currentRecCount: 0,
     setCurrentRecCount: () => {},
     rehberFilterInputs: [],
     setRehberFilterInputs: () => {},
     filterData: [],
     setFilterData: () => {},
});

export const AuthContextProvider=({children}) => {

     const [currentPageName, setCurrentPageName] = useState("");
     const [currentSubPageName, setCurrentSubPageName] = useState("");
     const [currentRecCount, setCurrentRecCount] = useState(-1);
     const [rehberFilterInputs, setRehberFilterInputs] = useState([]);
     const [filterData, setFilterData] = useState([]);
     

     const value = { currentPageName, setCurrentPageName, currentSubPageName, setCurrentSubPageName,currentRecCount, setCurrentRecCount,rehberFilterInputs, setRehberFilterInputs};

     const MyState = {
          company: localStorage.company,
          
          // BasvurularFilter: [],
          HTML: null,
          DATA: null,
          Loader: false,
          //RehberDetaySpos: {pos1:0,pos2:0},
          isLoggedIn: LoggedIn(),
          cache: [],
          getrehberdata_postparam: {},
          rehberdetay_postparam: {},
          //getparam:"",
          cacheEnabled: false,
     }

     //const [filterData, setFilterData] = useState([]);

     const SearchInCache = (url, param) => {
          var data = null;
          MyState.cache.forEach((element) => {
            var difference = (new Date() - element.time) / 1000;
            if (element.url == url && element.param === param && difference > 180) {
              const index = MyState.cache.indexOf(element);
              if (index > -1) {
                MyState.cache.splice(index, 1);
              }
            };
      
          });
          MyState.cache.forEach((element) => {
            var difference = (new Date() - element.time) / 1000;
            if (element.url == url && element.param === param && difference < 180) { data = element.data };
            // console.log("delta  :"+context.cache.length+"  "+difference);
          })
          return data;
        }
      
      
        const DeleteFromCache = (url, param) => {
      
          MyState.cache.forEach((element) => {
            if (element.url == url && element.param === param) {
              const index = MyState.cache.indexOf(element);
              if (index > -1) {
                //alert(param);
      
                MyState.cache.splice(index, 1);
              }
            };
      
          });
      
        }
      
        const showButtonloader = (buttonId) => {
          var el = $('#' + buttonId);
          //el.removeClass('fa fa-spinner fa-spin');
          el.addClass('fa fa-spinner fa-spin');
        }

        const hideButtonloader = (buttonId) => {
          var el = $('#' + buttonId);
          el.removeClass('fa fa-spinner fa-spin');
        }
         
     
        const loadScript = (src) => {
          var tag = document.createElement('script');
          //tag.async = true;
          tag.src = src;
          document.body.appendChild(tag);
          document.body.removeChild(tag);
        }
      
        const LoadAllScripts = () => {
          //this.loadScript("../assets/plugins/global/plugins.bundle.js");
          this.loadScript("../assets/js/scripts.bundle.js");
          //this.loadScript("../assets/plugins/custom/fullcalendar/fullcalendar.bundle.js");
          //this.loadScript("../assets/js/custom/widgets.js");
          //this.loadScript("../assets/js/custom/apps/chat/chat.js");
          //this.loadScript("../assets/js/custom/modals/create-app.js");
          //this.loadScript("../assets/js/custom/modals/upgrade-plan.js");
        }

     const setLoader = (Loader) => {
          MyState.Loader = Loader;
          if (Loader) $('#loadericondiv').show(); else $('#loadericondiv').hide();
        };
        
        return (
          <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
     );
}

export default AuthContext;